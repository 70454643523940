import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    ModalCloseButton,
    FormControl,
    FormErrorMessage
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import hotToast from "react-hot-toast";
import { useSelector } from "react-redux";
import { PersonaFormState } from "../../store/slices/persona.slice";
import { PaymentMethodOptionLabel } from "../PaymentMethodCard";
import { useHttpClient } from "../../utils/http.utils";
import { showPaymentIntentStatusMessage } from "../../utils/stripe";
import { config } from "../../config";
import { PaymentIntent } from "@stripe/stripe-js";
import { Formik, Form, Field } from "formik";
import { Validators } from "../../utils/validators.utils";
import { PaymentMethodModel } from "../../models/payment.model";
import Select from "react-select";
import { GoCheckCircleFill } from "react-icons/go";

interface NotarizePersonaModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

export default function NotarizePersonaModal(props: NotarizePersonaModalProps) {
    const modalDisc = useDisclosure();
    const { createPaymentChargeApi } = useHttpClient();
    const personaFormState: PersonaFormState = useSelector(
        (state: any) => state.persona.personaAttributesForm
    );
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("");
    const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [slide, setSlide] = useState(1);

    useEffect(() => {
        if (props.isOpen && !modalDisc.isOpen) {
            modalDisc.onOpen();
        } else {
            modalDisc.onClose();
        }
        // Construct payment method select options
        if (paymentMethods.length > 0) {
            setPaymentMethodOptions(
                paymentMethods.map((pm) => ({
                    value: pm.stripe_id,
                    label: `**** **** **** ${pm.card_extract}`,
                    card_brand: pm.card_brand,
                    expiry_date: pm.expiry_date
                }))
            );
        }
    }, [props.isOpen, paymentMethods]);

    const close = () => {
        modalDisc.onClose();
        setSlide(1);
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const proceedToPayment = () => {
        setSlide(2);
    };

    const payPersonaDNA = async (values) => {
        try {
            setIsSaving(true);
            const { paymentIntent }: { paymentIntent: PaymentIntent } =
                await createPaymentChargeApi(
                    {
                        amount: 10,
                        payment_method: values.payment_method,
                        metadata: {
                            persona: personaFormState.id
                        },
                        return_url: `${process.env.REACT_APP_URL}/payments/callback/credit-purchase?motive=${config.paymentMotive.personaDna}`
                    },
                    config.paymentMotive.personaDna
                );

            /**
             * The data return from the server is the stripe payment intent object,
             *  @see https://stripe.com/docs/api/payment_intents/object
             *
             * We need to do two things, if the next_action, is returned we need to handle that with stripe
             * Check the status of the payment intent
             */
            if (paymentIntent.next_action) {
                // handle next actions
                // @ts-ignore
                const { error, paymentIntent } = await stripe.handleCardAction(
                    // @ts-ignore
                    paymentIntent.client_secret
                );
                // If error ocurred, tell user payment could not be completed
                if (error) {
                    hotToast.error(
                        error.message || "We are unable to complete your payment at this time"
                    );
                } else {
                    hotToast(showPaymentIntentStatusMessage(paymentIntent.status));
                }
            } else {
                hotToast(showPaymentIntentStatusMessage(paymentIntent.status));
                // If success close modal
                console.log(paymentIntent.status);
                if (paymentIntent.status === "succeeded") {
                    hotToast.custom((t) => (
                        <div
                            className={`${t.visible ? "animate-enter" : "animate-leave"} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                        >
                            <div className="flex-1 w-0 p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0 pt-0.5">
                                        <GoCheckCircleFill className="text-green-500" size="35px" />
                                    </div>
                                    <div className="ml-3 flex-1">
                                        <p className="text-sm font-medium text-gray-900">
                                            Your payment was successful
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Your payment was successful, your persona DNA will be
                                            available in a moment, if it doesn't show after a while,
                                            refresh the page.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex border-l border-gray-200">
                                <button
                                    onClick={() => hotToast.dismiss(t.id)}
                                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    ));
                }
            }
            close();
            setIsSaving(false);
        } catch (e: any) {
            setIsSaving(false);
            hotToast.error(e.toString());
        }
    };

    return (
        <Modal
            size={"lg"}
            isCentered={true}
            isOpen={modalDisc.isOpen}
            onClose={close}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <h2 className="text">Copyright Persona DNA</h2>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt="0">
                    <div className="slide-container flex items-start overflow-x-hidden whitespace-nowrap">
                        <div
                            className={`slide-1 w-full inline-block flex-[0_0_100%] whitespace-pre-line transform transition-transform duration-500 ease-in-out ${slide > 1 ? "-translate-x-full" : "translate-x-0"}`}
                        >
                            <div className="border-l">
                                <div className="pl-[25px] relative record mb-[16px]">
                                    <span className="absolute block bg-gray-600 h-[10px] w-[10px] left-[-6px] top-1 rounded-full"></span>
                                    <p className="text-[13px]">
                                        We will convert your Persona’s information into a unique
                                        structural representation (Digital DNA).
                                    </p>
                                </div>
                                <div className="pl-[25px] relative record mb-[16px]">
                                    <span className="absolute block bg-gray-600 h-[10px] w-[10px] left-[-6px] top-1 rounded-full"></span>
                                    <p className="text-[13px]">
                                        We will initiate a transaction on the Talos Blockchain
                                        (Ethereum fork), and anchor it within a block.
                                    </p>
                                </div>
                                <div className="pl-[25px] relative record mb-[16px]">
                                    <span className="absolute block bg-gray-600 h-[10px] w-[10px] left-[-6px] top-1 rounded-full"></span>
                                    <p className="text-[13px]">
                                        A transaction ID for the immutable transaction is received
                                        (Notarization). This will be displayed in Past Notarization
                                        Information. Allow up to 5 minutes for this confirmation to
                                        be received.
                                    </p>
                                </div>
                            </div>

                            <div className="mb-6">
                                <span className="mb-1 text-sm text-slate-500">
                                    Notarization Cost:
                                </span>
                                <p className="text-2xl mb-4">$10.00</p>
                                <Button onClick={proceedToPayment} colorScheme="brand">
                                    Proceed to Payment
                                </Button>
                            </div>
                        </div>

                        <div
                            className={`slide-2 w-full inline-block flex-[0_0_100%] transform transition-transform duration-500 ease-in-out ${slide > 1 ? "-translate-x-full" : "translate-x-0"}`}
                        >
                            <Formik
                                initialValues={{
                                    payment_method: ""
                                }}
                                onSubmit={payPersonaDNA}
                            >
                                {(props) => (
                                    <Form>
                                        <div className="amount">
                                            <span className="mb-1 text-sm text-slate-500">
                                                Amount
                                            </span>
                                            <p className="text-2xl mb-4">$10.00</p>
                                        </div>
                                        <div className="mt-[16px]">
                                            <Field
                                                name="payment_method"
                                                validate={(e) =>
                                                    Validators.validateRequired("Payment Method", e)
                                                }
                                            >
                                                {({ field, form }) => (
                                                    <FormControl
                                                        mb={3}
                                                        isInvalid={
                                                            form.errors.payment_method &&
                                                            form.touched.payment_method
                                                        }
                                                    >
                                                        <p className="text-[13px] font-medium mb-1">
                                                            Payment Method
                                                        </p>
                                                        <Select
                                                            placeholder="Select payment method"
                                                            value={paymentMethodOptions.find(
                                                                (option) =>
                                                                    field.value
                                                                        ? field.value.includes(
                                                                              option.value
                                                                          )
                                                                        : ""
                                                            )}
                                                            isSearchable={false}
                                                            onChange={(selectedOption) => {
                                                                form.setFieldValue(
                                                                    "payment_method",
                                                                    (selectedOption as any)?.value
                                                                );
                                                            }}
                                                            onBlur={field.onBlur}
                                                            formatOptionLabel={
                                                                PaymentMethodOptionLabel
                                                            }
                                                            options={paymentMethodOptions}
                                                            className="arin-react-select-container"
                                                            classNamePrefix="arin-react-select"
                                                        />
                                                        <FormErrorMessage>
                                                            {form.errors.payment_method}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="mt-[16px]">
                                            <Button
                                                isLoading={isSaving}
                                                type="submit"
                                                colorScheme="brand"
                                            >
                                                Pay for DNA
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
