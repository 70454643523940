import ArinLayout from "../components/ArinLayout";
import "../styles/Personas.component.scss";
import {
    Button,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import PersonaSideBar from "../components/personas/PersonaSideBar";
import { PersonaModel } from "../models/persona.model";
import { useHttpClient } from "../utils/http.utils";
import { Field, Form, Formik } from "formik";
import PersonaHeader from "../components/personas/PersonaHeader";
import PersonaAttribute from "../components/personas/PersonaAttribute";
import PersonaMemories from "../components/personas/PersonaMemories";
import PersonaPersonality from "../components/personas/PersonaPersonality";
import PersonaInterface from "../components/personas/PersonaInterface";
import CustomTabs from "../components/customTabs/CustomTabs";
import PersonaPerformance from "../components/personas/PersonaPerformance";
import { useDispatch, useSelector } from "react-redux";
import { PersonaState, setPersonaForm, updatePersona } from "../store/slices/persona.slice";
import PersonalPortal from "../components/personas/PersonaPortal";
import PersonaAutonomy from "../components/personas/PersonaAutonomy";
import { Validators } from "../utils/validators.utils";
import { getPersonaFullName } from "../utils/personas.utils";
import { AccountState } from "../store/slices/account.slice";
import hotToast from "react-hot-toast";
import { TbBulb } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
import { setMetaTags } from "../utils/seo.utils";
import KeycloakClient from "../providers/auth/keycloak-client";
import PersonaSkills from "../components/personas/PersonaSkills";

const typeOptions = [
    { label: "Arin", value: "arin" },
    { label: "Sylis", value: "sylis" },
    { label: "Clone", value: "clone" },
    { label: "Inanimate", value: "inanimate" }
];

export default function PersonasView() {
    const [authUser, setAuthUser] = useState<any>(null);
    // Inside your component
    const [searchParams] = useSearchParams();

    // Get a specific query parameter
    const personaView = searchParams.get("view");

    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const { accountData, subscription, subscriptionStatus }: AccountState = useSelector(
        (state: any) => state.account
    );

    const createPersonaModal = useDisclosure();
    const [isControlsFormVisible, setIsControlsFormVisible] = useState(false);
    // const [personasList, setPersonasList] = useState<PersonaModel[]>([])
    const [selectedPersona, setSelectedPersona] = useState<PersonaModel>();
    const { createPersonaApi, fetchPersonaApi } = useHttpClient();
    const [formMode, setFormMode] = useState<"edit" | "create" | "none">("none");

    const [formInitialValues, setFormInitialValues] = useState<PersonaModel>({
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        type: "sylis",
        parent_list: []
    });

    const [isSaving, setIsSaving] = useState(false);

    const dispatch = useDispatch();

    setMetaTags({ title: "Personas" });

    useEffect(() => {
        (async () => {
            // Set Auth user
            setAuthUser(await KeycloakClient.getInstance().getAuthProfile());
        })();
    }, []);

    useEffect(() => {
        if (selectedPersona) {
            setSelectedPersona(
                personaState.personas.find((persona) => persona.id === selectedPersona.id)
            );
        }
        // If the persona view is set to general entity, show the general entity
        if (personaView === "general-entity") {
            setSelectedPersona(personaState.generalAIEntity);
        }
    }, [personaState]);

    const personaSelectOptions = useMemo(() => {
        const options = personaState.personas
            .map((persona) => {
                const value = persona?.id ?? "";
                const label = `${persona.first_name ?? ""} ${persona.last_name ?? ""}`.trim();
                const type = persona?.type ?? "";
                const category = "persona";
                return { value, label, type, category };
            })
            .filter((option) => option.value && option.label);

        // const authUserOption = {
        //     value: accountData?.id ?? "",
        //     label: authUser?.username ?? "",
        //     type: "Human",
        //     category: "user",
        // };

        // if (authUserOption.value && authUserOption.label) {
        //     return [...options, authUserOption];
        // }
        return options;
    }, [personaState.personas, authUser]);

    const onSelectPersonas = (values: any) => {
        const personas: PersonaModel[] = values.map((v) => {
            return personaState.personas.find((p) => p.id === v.value) as PersonaModel;
        });
        // etSelectedPersonas(personas);
    };

    const steps = () => {
        return personaState.personaAttributesForm.is_general_entity === "yes"
            ? [
                  {
                      name: "Attributes",
                      content: <PersonaAttribute />
                  }
                  // {
                  //     name: "Performance",
                  //     content: <PersonaPerformance />,
                  // },
              ]
            : [
                  {
                      name: "Attributes",
                      content: <PersonaAttribute />
                  },
                  {
                      name: "Skills",
                      content: (
                          <PersonaSkills
                              persona={selectedPersona}
                              generalAIEntity={personaState.generalAIEntity}
                          />
                      )
                  },
                  {
                      name: "Memories and paths",
                      content: <PersonaMemories persona={selectedPersona} />
                  },
                  {
                      name: "Personality",
                      content: <PersonaPersonality />
                  },
                  {
                      name: "Interface",
                      content: <PersonaInterface persona={selectedPersona} />
                  },
                  {
                      name: "Performance",
                      content: <PersonaPerformance persona={selectedPersona} />
                  },
                  {
                      name: "Autonomy",
                      content: <PersonaAutonomy persona={selectedPersona} />
                  }
              ];
    };

    const showCreatePersonaForm = () => {
        setFormMode("create");
        // Reset form
        resetForm();
    };

    const resetForm = () => {
        setFormInitialValues({
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            username: "",
            type: "sylis"
        });
    };

    const handlePersonaSelect = (persona) => {
        setSelectedPersona(persona);
        // update form
        // setFormInitialValues(persona);
        // Set the form mode
        setFormMode("edit");
        /**
         * Add the persona data to the main form
         */
        dispatch(setPersonaForm(persona));
    };

    async function onSaveNewPersona(values: PersonaModel, actions: any) {
        try {
            // validate persona parents
            let error = validatePersonaParents(values.parent_list);

            if (error) {
                hotToast.error(error);
                return;
            }

            const personaType = typeOptions.find((option) => option.value === values.type);
            const modifiedValues = {
                ...values,
                type: personaType?.value
            };
            const createdPersona = await createPersonaApi(modifiedValues);

            if (createdPersona) {
                // Use existing persona state to populate parent_list for created persona
                if (
                    Array.isArray(modifiedValues.parent_list) &&
                    modifiedValues.parent_list.length > 0 &&
                    typeof modifiedValues.parent_list[0] === "string"
                ) {
                    let populatedParentList: Array<PersonaModel | null> = [];
                    populatedParentList = (modifiedValues.parent_list as string[]).map(
                        (parentId) =>
                            personaState.personas.find((persona) => persona.id === parentId) || null
                    );

                    dispatch(
                        updatePersona({
                            ...createdPersona,
                            parent_list: populatedParentList
                        })
                    );
                }

                hotToast.success("Persona created successfully");

                createPersonaModal.onClose();
            }
        } catch (error) {
            console.log("~ file: PersonasView.tsx:124 ~ onFormSave ~ error:", error);
            // hotToast.error("We cannot create a person at the moment")
        } finally {
            actions.setSubmitting(false);
        }
    }

    const onDeletePersona = async (id: string) => {
        setFormMode("none");
    };

    const openPersonaPortal = (id: string) => {
        // get persona
        const activeOrg = window.sessionStorage.getItem("active_org");
        const persona = personaState.personas.find((p) => p.id === id),
            url = new URL(`${process.env.REACT_APP_NEO_UI_URL}/open-portal?vendor=${activeOrg}`);

        url.searchParams.set("pid", id);
        url.searchParams.set("pname", getPersonaFullName(persona as PersonaModel));
        // navigate to route
        window.open(url.toString());
    };

    const validatePersonaParents = (parendIdList) => {
        let error;
        /**
         * If one parent is selected enforce that the second parent is selected
         */
        if (parendIdList.length === 1) {
            error = "Please select the second parent";
            // if (parendIdList[0].persona) {
            // }
        } else if (parendIdList.length > 2) {
            error = "You cannot select more than two parents";
        }

        return error;
    };

    return (
        <ArinLayout>
            <div className="three-col-layout">
                <div className="left-pane grid-column">
                    <PersonaSideBar
                        onCreatePersona={() => createPersonaModal.onOpen()}
                        onPersonaSelect={handlePersonaSelect}
                    />
                </div>
                <div className="main grid-column">
                    {formMode === "none" && (
                        <div className="empty-state">
                            <div className="empty-content">
                                <div className="title">Select Persona or Create Personas</div>
                            </div>
                        </div>
                    )}

                    {formMode === "edit" && (
                        <div className="personas-body ">
                            <PersonaHeader
                                authUser={authUser}
                                onPortalClick={openPersonaPortal}
                                onKill={onDeletePersona}
                            />
                            <div
                                className={`persona-content ${!isControlsFormVisible && "expand"}`}
                            >
                                <div className="persona-form h-full">
                                    <CustomTabs tabs={steps()} />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Create Persona  Modals*/}
                    <Modal
                        size="xl"
                        isOpen={createPersonaModal.isOpen}
                        onClose={createPersonaModal.onClose}
                        isCentered
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <Formik initialValues={formInitialValues} onSubmit={onSaveNewPersona}>
                                {(props) => (
                                    <Form>
                                        <ModalHeader>Create Persona</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>
                                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                                <GridItem colSpan={1}>
                                                    <Field
                                                        name="first_name"
                                                        validate={(value) => {
                                                            let error;

                                                            if (!value) {
                                                                error =
                                                                    "Please insert the first name";
                                                            }

                                                            return error;
                                                        }}
                                                    >
                                                        {({ field, form }) => (
                                                            <FormControl
                                                                mb={3}
                                                                isInvalid={
                                                                    form.errors.first_name &&
                                                                    form.touched.first_name
                                                                }
                                                            >
                                                                <div className="">
                                                                    <div className="form-label mb-1">
                                                                        <p className="label-text w-24">
                                                                            First Name:
                                                                        </p>
                                                                    </div>
                                                                    <Input
                                                                        {...field}
                                                                        placeholder="Enter first name"
                                                                    />
                                                                </div>
                                                                <FormErrorMessage>
                                                                    {form.errors.first_name}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                                <GridItem colSpan={1}>
                                                    <Field
                                                        name="last_name"
                                                        validate={(value) => {
                                                            let error;

                                                            if (!value) {
                                                                error =
                                                                    "Please insert the last name";
                                                            }

                                                            return error;
                                                        }}
                                                    >
                                                        {({ field, form }) => (
                                                            <FormControl
                                                                mb={3}
                                                                isInvalid={
                                                                    form.errors.last_name &&
                                                                    form.touched.last_name
                                                                }
                                                            >
                                                                <div className="">
                                                                    <div className="form-label mb-1">
                                                                        <p className="label-text w-24">
                                                                            Last Name:
                                                                        </p>
                                                                    </div>
                                                                    <Input
                                                                        {...field}
                                                                        placeholder="Enter last name"
                                                                    />
                                                                </div>
                                                                <FormErrorMessage>
                                                                    {form.errors.last_name}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                            </Grid>

                                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                                <GridItem colSpan={1}>
                                                    <Field
                                                        name="email"
                                                        validate={(value) => {
                                                            let error;

                                                            if (!value) {
                                                                error = "Please insert the email";
                                                            } else if (
                                                                !Validators.validateEmail(value)
                                                            ) {
                                                                error =
                                                                    "Please insert a valid email";
                                                            }

                                                            return error;
                                                        }}
                                                    >
                                                        {({ field, form }) => (
                                                            <FormControl
                                                                mb={3}
                                                                isInvalid={
                                                                    form.errors.email &&
                                                                    form.touched.email
                                                                }
                                                            >
                                                                <div className="">
                                                                    <div className="form-label mb-1">
                                                                        <p className="label-text w-24">
                                                                            Email:
                                                                        </p>
                                                                    </div>
                                                                    <Input
                                                                        {...field}
                                                                        placeholder="Enter email"
                                                                    />
                                                                </div>
                                                                <FormErrorMessage>
                                                                    {form.errors.email}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                                <GridItem colSpan={1}>
                                                    <Field
                                                        name="username"
                                                        validate={(value) => {
                                                            let error;

                                                            if (!value) {
                                                                error =
                                                                    "Please insert the username";
                                                            }

                                                            return error;
                                                        }}
                                                    >
                                                        {({ field, form }) => (
                                                            <FormControl
                                                                mb={3}
                                                                isInvalid={
                                                                    form.errors.username &&
                                                                    form.touched.username
                                                                }
                                                            >
                                                                <div className="">
                                                                    <div className="form-label mb-1">
                                                                        <p className="label-text w-24">
                                                                            Username:
                                                                        </p>
                                                                    </div>
                                                                    <Input
                                                                        {...field}
                                                                        placeholder="Enter username"
                                                                    />
                                                                </div>
                                                                <FormErrorMessage>
                                                                    {form.errors.username}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                            </Grid>

                                            <Field name="parent_list">
                                                {({ field, form }) => (
                                                    <FormControl>
                                                        <div className="form-label mb-1">
                                                            <p className="label-text w-24">
                                                                Parents:
                                                            </p>
                                                        </div>
                                                        <Select
                                                            isMulti
                                                            className="arin-react-select-container"
                                                            classNamePrefix="arin-react-select"
                                                            isClearable={false}
                                                            name="parent_list"
                                                            value={personaSelectOptions.filter(
                                                                (option) =>
                                                                    field.value.some(
                                                                        (val) =>
                                                                            val === option.value
                                                                    )
                                                            )}
                                                            onChange={(selectedOptions) => {
                                                                const modifiedData = (
                                                                    selectedOptions || []
                                                                ).map((option) => option.value);

                                                                form.setFieldValue(
                                                                    "parent_list",
                                                                    modifiedData
                                                                );
                                                            }}
                                                            onBlur={field.onBlur}
                                                            options={personaSelectOptions}
                                                        />
                                                        <FormErrorMessage>
                                                            {form.errors.parent_list}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>

                                            <div className="border rounded-[8px] mt-4 bg-gray-50 p-2">
                                                <p className="m-0 text-[13px]">
                                                    <strong>Important.</strong>
                                                </p>
                                                <p className="text-slate-500 text-[13px]">
                                                    This is the birthing process of Arin. Only
                                                    proceed if you understand the system. Note that
                                                    once entered, the information whose fields are
                                                    denoted with a <strong>*</strong> cannot be
                                                    updated. You will have to &nbsp;
                                                    <strong>"kill"</strong>&nbsp;the being and
                                                    restart the process over.
                                                </p>
                                            </div>
                                        </ModalBody>

                                        <ModalFooter display="flex" justifyContent="center">
                                            <Button
                                                loadingText="Creating.."
                                                mt={4}
                                                type="submit"
                                                variant="brand"
                                                isLoading={props.isSubmitting}
                                                background={"#1E1E1E"}
                                                color={"#FFF"}
                                                width={"100%"}
                                            >
                                                Create
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                )}
                            </Formik>
                        </ModalContent>
                    </Modal>
                </div>
                <div className="right-pane grid-column">
                    <PersonalPortal />
                </div>
            </div>
        </ArinLayout>
    );
}
