import {
    Button,
    Checkbox,
    CheckboxIcon,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SecretKeysTable } from "./SecretKey";
import EmailList from "../EmailList";
import { useSelector } from "react-redux";
import { PersonaState } from "../../store/slices/persona.slice";
import { useHttpClient } from "../../utils/http.utils";
import { PersonaModel } from "../../models/persona.model";
import { OrganizationModel } from "../../models/organization.model";

// TODO: move to models folder
type PersonaInterfaceProps = {
    moveToNext?: () => void;
    moveToPrevious?: () => void;
    persona?: PersonaModel;
};

const PersonaInterface = ({ moveToNext, moveToPrevious, persona }: PersonaInterfaceProps) => {
    const { updatePersonaApi, listOrganizationApi } = useHttpClient();
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const [pricingPerWord, setPricingPerWord] = useState("");
    const toast = useToast();
    const { accountData: loggedInUser } = useSelector((state: any) => state.account);
    const [organization, setOrganization] = useState<OrganizationModel>();

    useEffect(() => {
        if (persona?.pricing_per_word) {
            setPricingPerWord(String(persona.pricing_per_word));
        } else {
            setPricingPerWord("0");
        }
    }, [persona]);

    useEffect(() => {
        // Fetch default organization
        if (loggedInUser && !organization) {
            listOrganizationApi({ user: loggedInUser.id }).then((response: any) => {
                setOrganization(response[0]);
            });
        }
    }, [loggedInUser]);

    const onSavePricing = (e) => {
        if (isNaN(Number(pricingPerWord))) {
            toast({
                title: "Please enter a valid number",
                status: "warning"
            });
            return;
        }

        if (persona) {
            updatePersonaApi({
                id: persona.id!,
                pricing_per_word: Number(pricingPerWord)
            });
        }
    };

    const changePricingPerWord = (e) => {
        setPricingPerWord(e.target.value);
    };

    // const updateInteractionHistory = (e) => {
    //     if (persona) {

    //         updatePersonaApi({
    //             id: persona.id!,
    //             keep_interaction_history: e.target.checked ? "yes" : "no"
    //         });
    //     }
    // }

    const updateInteractionHistory = async (data: PersonaModel) => {
        if (persona?.id) {
            await updatePersonaApi(data);
        }
    };

    return (
        <div className="mt-4 pb-8">
            <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">Interface</h1>
            <p className="text-[13px] text-gray-500">
                An interface allows your Ai to connect to the outside world.
            </p>
            <div className="ml-6 mt-6">
                <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">Create API Keys</h1>
                <p className="text-[13px] text-gray-500">
                    Your secret API keys are listed below. Please note that we do not display your
                    secret keys again after you generate them.
                    <br />
                    Do not share your secret API keys with others. or expose it in the browser or
                    other client-side coe. In other to protect the security of your account. OpenAi
                    may also automatically disable any API key that we've found has leaked publicly.
                </p>
                {/* API KEY COMPONENT */}
                <SecretKeysTable />

                <h1 className="mt-6 mb-[7px] leading-[1] font-medium text-[16px]">Commerce</h1>
                <p className="mb-6 text-[13px] text-gray-500">
                    You can lease out your Ai entity. In this process, your Ai entity will learn
                    from others interacting with it. You can set amount per word that you'd like to
                    receive for interactions with your creation. You must be KYC'd for this process,
                    hold a Versa non-custodial account. Visit{" "}
                    <a target="" href="https://versa.finance" className="text-blue-500">
                        worldercore.com
                    </a>{" "}
                    to get set up.
                </p>
                <FormControl className="mb-5">
                    <Checkbox
                        isChecked={persona?.keep_interaction_history === "yes"}
                        // onChange={updateInteractionHistory}
                        onChange={() =>
                            updateInteractionHistory({
                                id: persona!.id,
                                keep_interaction_history:
                                    persona?.keep_interaction_history === "yes" ? "no" : "yes"
                            })
                        }
                        colorScheme="blue"
                    >
                        <p className="text-sm leading-6">
                            Keep Interaction in History? (We will display that to the user that
                            their interactions are used for training purposes).
                        </p>
                    </Checkbox>
                </FormControl>
                <div className="w-[400px]">
                    <FormControl>
                        <FormLabel className="text-gray-500 text-[13px]" htmlFor="price">
                            Price Per Word
                        </FormLabel>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none"
                                color="gray.300"
                                fontSize="1.2em"
                                children="$"
                            />
                            <Input
                                onBlur={onSavePricing}
                                value={pricingPerWord}
                                onChange={changePricingPerWord}
                            />
                            <InputRightElement>
                                <CheckboxIcon color="green.500" />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </div>
                <p className="text-sm text-slate-500 leading-6 mt-2">
                    Setting a price point on this will make your Ai entity public and discoverable
                    to all.
                </p>

                <h1 className="mt-10 mb-[7px] leading-[1] font-medium text-[16px]">Invites</h1>
                <p className="mb-4 text-[13px] text-gray-500">
                    You can invite others to interact with your Ai entity and pay for them (suitable
                    for businesses). This will keep the interactions with others private
                </p>
                <div className="w-full">
                    <EmailList organizationId={organization?.id} />
                </div>
                <p className="mb-6 mt-2 w-[650px] text-slate-400 text-[13px]">
                    It costs $10/user/month which adds 250.000 words to your account. Thereafter
                    words are on a user per word basis. You can set notification alerts so you don't
                    spend beyond your allocated budget.
                </p>
                {/* <Button
                    mt={4}
                    type="submit"
                    style={{
                        color: "#fff",
                        background: "#000",
                        width: "150px",
                    }}
                    onClick={moveToNext}
                >
                    Save
                </Button> */}
            </div>
        </div>
    );
};

export default PersonaInterface;
