import React, { useEffect } from "react";

import PersonalityTraitMCQ from "./PersonalityTraitMCQ";
import { PersonaState, updatePersona } from "../../store/slices/persona.slice";
import { useDispatch, useSelector } from "react-redux";

type PersonaPersonalityTraitProps = {
    markAsCompleted?: (completedStatus: boolean) => void;
    onComplete?: () => void;
};

const PersonaPersonalityTrait = ({ markAsCompleted, onComplete }: PersonaPersonalityTraitProps) => {
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const dispatch = useDispatch();

    useEffect(() => {
        if (personaState.personaAttributesForm.personality_trait_status === "completed") {
            if (markAsCompleted) {
                markAsCompleted(true);
            }
        } else {
            if (markAsCompleted) {
                markAsCompleted(false);
            }
        }
    }, [personaState.personaAttributesForm]);

    const completeForm = () => {
        // update persona state
        dispatch(
            updatePersona({
                id: personaState.personaAttributesForm.id,
                personality_trait_status: "completed"
            })
        );
        if (typeof markAsCompleted === "function") {
            markAsCompleted(true);
        }
        // if (typeof onComplete === "function") {
        //     onComplete();
        // }
    };

    return (
        <div className="">
            <p className="text-[14px] mb-8 text-slate-500">
                To identify personality traits we engage in an assessment and store the results in a
                table. This is done through few shot architecture.
            </p>
            <PersonalityTraitMCQ onSaved={completeForm} />
        </div>
    );
};

export default PersonaPersonalityTrait;
