import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CollaborateState,
    setEnableCollaborationInput,
    setLastDecisionMessageId,
    updateAutoInteractionMessage
} from "../../store/slices/collaborate.slice";
import "../../styles/CollaborateMessage.component.scss";
import {
    formatCollaborateMessage,
    formatCollabMarkDownMessage,
    formatCollaborationAppFileLink
} from "../../utils/collaborate/parser";
import { Button, ButtonGroup, Textarea, useToast } from "@chakra-ui/react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useHttpClient } from "../../utils/http.utils";
import { AutoInteractionMessage } from "../../models/collaboration.model";
import { getPersonaFullName } from "../../utils/personas.utils";
import hotToast from "react-hot-toast";
import InteractionMessageDecision, { DecisionFormValues } from "./InteractionMessageDecision";
import Markdown from "markdown-to-jsx";
import InteractiveInput from "../interactiveInput";
import { AccountState } from "../../store/slices/account.slice";
import { capitalizeWord } from "../../utils/strings.utils";
import { UserModel } from "../../models/user.model";
import { getUserName } from "../../utils/user.utils";

export function InputMessageTypeForm({
    message,
    onEdit
}: {
    message: string;
    onEdit?: (msg: string) => void;
}) {
    const [editMode, setEditMode] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (editMode) {
            const input = inputRef.current as unknown as HTMLInputElement;
            input.value = message;
        }
    }, [editMode]);

    const onSave = () => {
        if (typeof onEdit === "function") {
            const input = inputRef.current as unknown as HTMLInputElement;
            onEdit(input.value);
        }
        setEditMode(false);
    };

    return (
        <div className="input-message-form-container my-2">
            {editMode ? (
                <div className="input-message-form">
                    <div className="input">
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder="Enter suggestion"
                            name=""
                            id=""
                        />
                    </div>
                    <div className="actions">
                        <button className={"save-btn"} onClick={onSave}>
                            <AiOutlineCheck className="icon" />
                        </button>
                        <button className={"cancel-btn"} onClick={() => setEditMode(false)}>
                            <AiOutlineClose className="icon" />
                        </button>
                    </div>
                </div>
            ) : (
                <div className="input-message-text">
                    <p className="text">{message}</p>
                    <button className="edit-btn" onClick={() => setEditMode(true)}>
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
}

export default function InteractionMessage({ message }: { message: AutoInteractionMessage }) {
    const collaborate_state: CollaborateState = useSelector((state) => (state as any).collaborate);
    const searches = useSelector((state) => (state as any).searches);
    const [hasDecided, setHasDecided] = useState(false);
    const toast = useToast();
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const humanFeedbackInputRef = useRef<HTMLTextAreaElement>(null);
    const [showingFeedbackInput, setShowingFeedbackInput] = useState(false);
    const [displayUserInputs, setDisplayUserInputs] = useState<boolean>(true);
    const [recentUserInteraction, setRecentUserInteraction] =
        useState<AutoInteractionMessage | null>();
    const { initializeCollaborationAppApi, sendExecutionCommandApi, executeToolWithInputsApi } =
        useHttpClient();
    const dispatch = useDispatch();
    const collaborateState: CollaborateState = useSelector((state) => (state as any).collaborate);
    const accountState: AccountState = useSelector((state: any) => state.account);

    const handleDecision = async (values: DecisionFormValues) => {
        if (values.proceedWithAction === "yes") {
            /**
             * Delete values.proceedWithAction, the property is only used by the UI
             * To know when to submit the form to the api
             */
            delete (values as any).proceedWithAction;
            // Hide yes/no buttons
            setHasDecided(true);
            // await proceedAutoInteractionActionApi(collaborate_state.autoInteractionId, collaborate_state.collaborationAppId, decision);
            initializeCollaborationAppApi(
                collaborate_state.autoInteractionId,
                collaborate_state.collaborationAppId,
                values
            ).catch((err) => {
                // Hide yes/no buttons
                setHasDecided(false);
            });
        } else {
            dispatch(setEnableCollaborationInput(true));
            // Hide yes/no buttons
            setHasDecided(true);
        }
    };

    const handleCollabDecision = async (
        decisionMessageType: AutoInteractionMessage["type"],
        decision: "yes" | "no",
        humanInput = ""
    ) => {
        if (!collaborate_state.isInitialized) {
            toast({
                title: "You will have to first initialize the session",
                status: "warning"
            });
            return;
        }

        let responseType:
            | "execute_next_cell"
            | "execute_previous_cell"
            | "execute_tool"
            | "cancel_execute_tool"
            | "" = "";

        if (
            collaborate_state.viewMode === "initialize" &&
            collaborate_state.selectedCollaborationApp?.id
        ) {
            if (decision === "yes") {
                switch (decisionMessageType) {
                    case "execute_next_cell":
                        responseType = "execute_next_cell";
                        break;
                    case "execute_tool":
                        responseType = "execute_tool";
                        break;
                    default:
                }
            } else if (decision === "no") {
                switch (decisionMessageType) {
                    case "execute_next_cell":
                        responseType = "execute_previous_cell";
                        break;
                    case "execute_tool":
                        responseType = "cancel_execute_tool";
                        break;
                    default:
                }
            }

            if (responseType !== "") {
                sendExecutionCommandApi(
                    collaborate_state.selectedCollaborationApp.id,
                    responseType,
                    humanInput
                );

                // Clear last decision message id
                dispatch(setLastDecisionMessageId(""));
            }
        }
    };

    const showInputContainer = () => {
        if (inputContainerRef.current) {
            inputContainerRef.current.classList.remove("page-exit");
            inputContainerRef.current.classList.add("page-entry");
        }
        if (humanFeedbackInputRef.current) {
            humanFeedbackInputRef.current.focus();
        }
        setShowingFeedbackInput(true);
    };

    const removeInputContainer = () => {
        if (inputContainerRef.current) {
            inputContainerRef.current.classList.remove("page-entry");
            inputContainerRef.current.classList.add("page-exit");
        }
        if (humanFeedbackInputRef.current) {
            humanFeedbackInputRef.current.value = "";
        }
        setShowingFeedbackInput(false);
    };

    const handleLastCellWithInput = (decisionMessageType: AutoInteractionMessage["type"]) => {
        // handle case...
        // if input is empty, return
        // if input is not empty, send execution command
        // clear last decision message id
        if (humanFeedbackInputRef.current) {
            const input = humanFeedbackInputRef.current;
            if (input.value === "") {
                hotToast.error("Please provide input");
                return;
            }
            handleCollabDecision(decisionMessageType, "no", input.value);
            removeInputContainer();
        }
    };

    const shouldShowControlButtons = () =>
        (message.type === "execute_next_cell" || message.type === "execute_tool") &&
        collaborate_state.lastDecisionMessageId === message.id;

    async function onUserInputSubmit(responses) {
        try {
            // hide input control
            dispatch(
                updateAutoInteractionMessage({
                    ...message,
                    user_input_submitted: true
                })
            );

            const res = await executeToolWithInputsApi(responses);
            if (res.success) {
                setDisplayUserInputs(false);
                setRecentUserInteraction(null);
            } else {
                // show input control
                dispatch(
                    updateAutoInteractionMessage({
                        ...message,
                        user_input_submitted: false
                    })
                );
                hotToast.error("Error Uploading file. Please try again.");
            }
        } catch (error) {
            // show input control
            dispatch(
                updateAutoInteractionMessage({
                    ...message,
                    user_input_submitted: false
                })
            );
            hotToast.error("Error Uploading file. Please try again.");
        }
    }

    return (
        <div className="collaborate-message">
            {message.message_type === "ai" && message.message_format === "normal" && (
                <div className="ai-message-container transition-all">
                    <div
                        className={
                            "ai-message" +
                            (shouldShowControlButtons() ? " with-control-buttons" : "")
                        }
                    >
                        <div className="character">
                            <p className="character-name">
                                {getPersonaFullName(collaborate_state.persona, message.persona) ||
                                    searches.character.character}
                            </p>
                            {/* <p className="character-message">
                                    {formatCollaborateMessage(message.message)}
                                </p> */}
                            <div className="auto-interaction-mdx leading-[170%]">
                                <Markdown options={{ forceBlock: true }}>
                                    {formatCollabMarkDownMessage(message.message)}
                                </Markdown>
                            </div>

                            {message.message && message.file ? <br></br> : <></>}
                            {message.file ? (
                                <p className="character-message character-message-file-link">
                                    {formatCollaborationAppFileLink(message.file)}
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                        {shouldShowControlButtons() && (
                            <div className="message-action my-[10px]">
                                <ButtonGroup>
                                    <Button
                                        onClick={() => showInputContainer()}
                                        size="sm"
                                        background="gray.100"
                                        color="gray.900"
                                        isDisabled={showingFeedbackInput}
                                    >
                                        Re-run previous cell with input
                                    </Button>
                                    <Button
                                        onClick={() => handleCollabDecision(message.type, "yes")}
                                        size="sm"
                                        background="blue.50"
                                        color="blue.500"
                                        isDisabled={showingFeedbackInput}
                                    >
                                        Proceed
                                    </Button>

                                    <Button
                                        onClick={() => handleCollabDecision(message.type, "no")}
                                        size="sm"
                                        background="red.50"
                                        color="red.500"
                                        isDisabled={showingFeedbackInput}
                                    >
                                        Rerun Previous Cell
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </div>
                    {shouldShowControlButtons() && (
                        <div
                            ref={inputContainerRef}
                            className="human-input-container bg-white grid grid-cols-[auto_175px] grid-rows-[100%] transition-transform mb-[10px]"
                        >
                            <div className="input h-full flex pb-3 px-4">
                                <Textarea
                                    ref={humanFeedbackInputRef}
                                    rows={1}
                                    placeholder="Enter input"
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="actions py-3">
                                <ButtonGroup>
                                    <Button
                                        onClick={() => handleLastCellWithInput(message.type)}
                                        size="sm"
                                        background="blue.50"
                                        color="blue.500"
                                    >
                                        Proceed
                                    </Button>
                                    <Button
                                        onClick={() => removeInputContainer()}
                                        size="sm"
                                        background="gray.100"
                                        color="gray.900"
                                    >
                                        Cancel
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {message.message_type === "user" && message.message_format === "normal" && (
                <div className="user-message-container">
                    <div className="user-message">
                        <p className="character-name">
                            {getUserName(accountState?.accountData as UserModel)}
                        </p>
                        <p className="character-message">
                            {formatCollaborateMessage(message.message)}
                        </p>
                    </div>
                </div>
            )}
            {message.message_type === "ai" && message.message_format === "decision" && (
                <InteractionMessageDecision
                    message={message}
                    hideActions={hasDecided}
                    onComplete={handleDecision}
                />
            )}

            {message.message_type === "ai" &&
                message.message_format === "input" &&
                !message.user_input_submitted && (
                    <div className="ai-message">
                        <div className="character">
                            <p className="character-message">
                                <InteractiveInput
                                    key={collaborateState.selectedCollaborationApp?.id}
                                    collaborationAppId={
                                        collaborateState.selectedCollaborationApp?.id as string
                                    }
                                    message={message}
                                    onUserInputSubmit={onUserInputSubmit}
                                />
                            </p>
                        </div>
                    </div>
                )}
        </div>
    );
}
