import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { useEffect } from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AccountState } from "../../store/slices/account.slice";

interface SwitchOrganizationsModalProps {
    isOpen: boolean;
    authUserToken?: string;
    onClose: () => void;
}

const SwitchOrganizationsModal: React.FC<SwitchOrganizationsModalProps> = (props) => {
    const modalDis = useDisclosure();
    const accountState: AccountState = useSelector((state: any) => state.account);
    const organizations = accountState.organizations;

    useEffect(() => {
        if (props.isOpen) {
            modalDis.onOpen();
        } else {
            modalDis.onClose();
        }
    }, [props.isOpen]);

    const closeViewModal = () => {
        modalDis.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const openBackend = (organizationId: string) => {
        if (props.authUserToken) {
            // @ts-ignore
            // TODO: WRITE LOGIC TO SWITHC ACCOUNT
            closeViewModal();
        }
    };

    return (
        <Modal size="lg" isOpen={modalDis.isOpen} onClose={closeViewModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Choose Organization</ModalHeader>
                <ModalBody pt={0}>
                    <div className="min-h-[200px]">
                        {organizations.map((org: any, index) => (
                            <div
                                onClick={() => openBackend(org.id)}
                                key={"org" + index}
                                aria-label="button"
                                className="org border px-3 cursor-pointer bg-gray-50 border-gray-300 hover:bg-blue-50 hover:border-blue-300 rounded-[8px] py-3 flex gap-[12px]"
                            >
                                <div className="icon">
                                    <div className="h-[45px] w-[45px] bg-gray-400 rounded-[8px] flex justify-center items-center">
                                        <FaBuilding size="25px" className="text-white" />
                                    </div>
                                </div>
                                <div className="details">
                                    <h2 className="text-[16px]">{org?.name}</h2>
                                    <h4 className="text-[13px] gap-[0px] flex items-center text-slate-400">
                                        <MdOutlineLocationOn size="15px" />
                                        {org?.address?.city}, {org?.address?.country}
                                    </h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SwitchOrganizationsModal;
