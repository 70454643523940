import { Box, VStack, Text } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";

interface TabProps {
    label: string;
    content: ReactNode;
    completed?: boolean;
}

interface CustomTabProps {
    tabs: TabProps[];
}

const LeftCustomTab: React.FC<CustomTabProps> = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tabsState, setTabsState] = useState(tabs);

    const markAsCompleted = (index: number, completedState = true) => {
        const newTabs = [...tabsState];
        newTabs[index].completed = completedState;
        setTabsState(newTabs);
    };

    return (
        <Box display="grid" gridTemplateColumns="250px auto" height="100%">
            <VStack
                spacing="30px"
                padding="20px"
                width={"250px"}
                // borderRight='2px solid gray'
                alignItems="flex-start"
                height={"50vh"}
            >
                {" "}
                {tabsState.map((tab, index) => (
                    <Box key={`box-${index}`} display="flex" alignItems="center" width="100%">
                        {" "}
                        <Text
                            cursor="pointer"
                            className={
                                activeTab === index
                                    ? "font-bold text-blue-400"
                                    : "normal text-slate-500"
                            }
                            onClick={() => setActiveTab(index)}
                            flex={1}
                        >
                            {tab.label}
                        </Text>
                        {tab.completed && (
                            <span className="text-green-500 text-lg">
                                <AiFillCheckCircle />
                            </span>
                        )}
                    </Box>
                ))}
            </VStack>
            {tabsState.map((tab, index) => (
                <Box
                    display={activeTab == index ? "block" : "none"}
                    padding="20px"
                    height="100%"
                    overflowY="auto"
                >
                    {React.cloneElement(tab.content as React.ReactElement, {
                        markAsCompleted: (completedState) => markAsCompleted(index, completedState)
                    })}
                </Box>
            ))}
        </Box>
    );
};

export default LeftCustomTab;
