import {
    Button,
    ButtonGroup,
    ChakraProvider,
    extendTheme,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Textarea
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { createRoot } from "react-dom/client";

const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            200: "#f7fafc",
            300: "#1a202c",
            400: "#1a202c",
            500: "#1a202c",
            600: "#1a202c",
            700: "#1a202c",
            800: "#1a202c",
            900: "#1a202c"
        }
    }
});

interface CloneCollaborationAppGroupProps {
    title: string;
}

export function CloneCollaborationAppGroupInput({ title }: CloneCollaborationAppGroupProps) {
    return new Promise<{ title: string; description: string }>((resolve, reject) => {
        const modalRoot = document.getElementById("arin-modal-root") as HTMLDivElement;
        const container = document.createElement("div");
        modalRoot.appendChild(container);

        const root = createRoot(container);

        const closeModal = () => {
            modalRoot.removeChild(container);
        };

        const handleCancel = () => {
            closeModal();
        };

        const handleSubmit = (values, { setSubmitting }) => {
            closeModal();
            resolve(values);
        };

        const component = (
            <ChakraProvider theme={theme}>
                <div className="arin-modal-container">
                    <div className="arin-modal-content sm">
                        <Formik
                            initialValues={{ title: "", description: "" }}
                            onSubmit={handleSubmit}
                            validate={(values) => {
                                const errors: Record<string, string> = {};
                                if (!values.title) {
                                    errors.title = "Title is required";
                                }
                                if (!values.description) {
                                    errors.description = "Description is required";
                                }
                                return errors;
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="arin-modal-header">
                                        <span className="text-lg">{title}</span>
                                    </div>
                                    <div className="arin-modal-body space-y-4">
                                        <Field name="title">
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        form.errors.title && form.touched.title
                                                    }
                                                >
                                                    <FormLabel htmlFor="title">Title</FormLabel>
                                                    <Input
                                                        {...field}
                                                        id="title"
                                                        placeholder="Title"
                                                    />
                                                    <FormErrorMessage>
                                                        {form.errors.title}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name="description">
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        form.errors.description &&
                                                        form.touched.description
                                                    }
                                                >
                                                    <FormLabel htmlFor="description">
                                                        Description
                                                    </FormLabel>
                                                    <Textarea
                                                        {...field}
                                                        id="description"
                                                        placeholder="Description"
                                                        rows={5}
                                                    ></Textarea>
                                                    <FormErrorMessage>
                                                        {form.errors.description}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="arin-modal-footer">
                                        <ButtonGroup spacing="2">
                                            <Button
                                                type="submit"
                                                colorScheme="blue"
                                                isLoading={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="outline"
                                                onClick={handleCancel}
                                                colorScheme="gray"
                                            >
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </ChakraProvider>
        );

        root.render(component);
    });
}
