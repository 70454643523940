export const config = {
    dailyHealthPointUsage: 3,
    maxHealthPointRefill: 100,
    creditPerWord: 0.001,
    paymentMotive: {
        purchaseCredit: "purchase_credit",
        personaDna: "persona_dna"
    }
};

export interface IAppConfigs {
    keycloak: {
        realm: string;
        url: string;
        clientId: string;
    };
    oneUI: string;
}

const Configs: IAppConfigs = {
    keycloak: {
        realm: process.env.REACT_APP_KEYCLOAK_REALM!,
        url: process.env.REACT_APP_KEYCLOAK_URL!,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!
    },
    oneUI: process.env.REACT_APP_ONE_UI_URL!
};

export function getAppConfig<T = string>(key: keyof typeof Configs): T {
    return Configs[key] as T;
}
