import {
    Button,
    ButtonGroup,
    ChakraProvider,
    extendTheme,
    FormControl,
    FormErrorMessage,
    Input
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            200: "#f7fafc",
            300: "#1a202c",
            400: "#1a202c",
            500: "#1a202c",
            600: "#1a202c",
            700: "#1a202c",
            800: "#1a202c",
            900: "#1a202c"
        }
    }
});
interface DeleteConfirmationModalProps {
    title: string;
    message?: string;
    keyword?: string;
}

export function DeleteConfirmation({
    title,
    message,
    keyword = "DELETE"
}: DeleteConfirmationModalProps) {
    return new Promise((resolve, reject) => {
        const handleConfirm = (values, action) => {
            if (values.action.toLowerCase() !== keyword.toLowerCase()) {
                action.validateForm();
                return;
            }
            closeModal();
            resolve(true);
        };

        const handleCancel = () => {
            closeModal();
            resolve(false);
        };

        const validateDelete = (value) => {
            let error;
            if (!value) {
                error = "Please confirm this action";
            } else if (value !== keyword.toUpperCase()) {
                error = `Please input ${keyword.toUpperCase()} to confirm this action`;
            }
            return error;
        };

        const modalRoot = document.getElementById("arin-modal-root") as HTMLDivElement;

        const container = document.createElement("div");
        modalRoot.appendChild(container);

        // create root
        const root = createRoot(container);

        const closeModal = () => {
            modalRoot.removeChild(container);
        };

        const component = (
            <ChakraProvider theme={theme}>
                <div className="arin-modal-container">
                    <div className="arin-modal-content sm">
                        <Formik initialValues={{ action: "" }} onSubmit={handleConfirm}>
                            {(props) => (
                                <Form>
                                    <div className="arin-modal-header">
                                        <span className="text-lg">{title}</span>
                                    </div>
                                    <div className="arin-modal-body">
                                        <div className="text-slate-500 mb-4 text-sm">
                                            {message}
                                            <p className="mb-1 mt-1">
                                                To confirm this action, type{" "}
                                                <strong>{keyword.toUpperCase()}</strong> in the
                                                input below
                                            </p>
                                        </div>
                                        <Field name="action" validate={validateDelete}>
                                            {({ field, form }) => (
                                                <FormControl
                                                    isInvalid={
                                                        form.errors.action && form.touched.action
                                                    }
                                                >
                                                    <Input
                                                        {...field}
                                                        placeholder="Confirm Action"
                                                    />
                                                    <FormErrorMessage>
                                                        {form.errors.action}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="arin-modal-footer">
                                        <ButtonGroup spacing="2">
                                            <Button
                                                type="submit"
                                                loadingText="Deleting"
                                                colorScheme="red"
                                            >
                                                {keyword.toUpperCase()}
                                            </Button>
                                            <Button
                                                variant="outline"
                                                onClick={handleCancel}
                                                colorScheme="gray"
                                            >
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </ChakraProvider>
        );

        root.render(component);
    });
}
