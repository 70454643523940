import "../styles/PromptEngineering.component.scss";
import ArinLayout from "../components/ArinLayout";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import KeycloakClient from "../providers/auth/keycloak-client";
import { useSelector } from "react-redux";
import { AccountState } from "../store/slices/account.slice";

export default function PromptEngineeringView() {
    const [searchParams] = useSearchParams();
    const liteGraphURL = process.env.REACT_APP_LITEGRAPH_URL;
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const activeOrg: string | undefined = accountState.vendorProfile?.id;

    const handleIframeLoad = async () => {
        const token = await KeycloakClient.getInstance().getAuthUserToken();
        const expiry = KeycloakClient.getInstance().getAuthUserTokenExpiry();

        iframeRef.current?.contentWindow?.postMessage({ token, expiry }, liteGraphURL!);
    };

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.addEventListener("load", handleIframeLoad);
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener("load", handleIframeLoad);
            }
        };
    }, []);

    useEffect(() => {
        const handleTokenRequest = async (event: MessageEvent) => {
            if (event.origin !== liteGraphURL) {
                return;
            }

            if (event.data === "requestToken") {
                try {
                    handleIframeLoad();
                } catch (error) {
                    console.error("Failed to retrieve token:", error);
                }
            }
        };

        window.addEventListener("message", handleTokenRequest);

        return () => {
            window.removeEventListener("message", handleTokenRequest);
        };
    }, []);

    return (
        <ArinLayout>
            <div className="prompt-engineering">
                <iframe
                    title="brain"
                    ref={iframeRef}
                    onLoad={handleIframeLoad}
                    src={`${liteGraphURL}?application_code=${searchParams.get("collaboration_app_group_id")}&vendor=${activeOrg || window.sessionStorage.getItem("active_org")}`}
                    className="prompt-engineer"
                ></iframe>
            </div>
        </ArinLayout>
    );
}
