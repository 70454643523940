import { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FormikProps } from "formik";
import {
    FormControl,
    Input,
    FormErrorMessage,
    Button,
    useToast,
    Spinner,
    FormHelperText,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Box,
    Tag,
    TagLabel,
    TagCloseButton
} from "@chakra-ui/react";
import { PersonaFormState } from "../../store/slices/persona.slice";
import { useSelector } from "react-redux";
import { useHttpClient } from "../../utils/http.utils";
import Select from "react-select";
import { PiTreeStructure } from "react-icons/pi";
import { getPersonaFullName } from "../../utils/personas.utils";
import PersonaProfilePhoto from "./PersonaProfilePhoto";
import { PersonaModel } from "../../models/persona.model";
import NotarizePersonaModal from "../modals/NotarizePersonaModal";
import NotarizedInformationModal from "../modals/NotarizedInformation";
import { FiPlus } from "react-icons/fi";

const formInitialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    description: "",
    specificities: "",
    username: "",
    date_of_birth: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    email: "",
    phone_number: "",
    gender: "",
    marital_status: "",
    address: "",
    profession: "",
    employer: "",
    height: "",
    distinguishing_features: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    private_key: "",
    ssn: "",
    religion: "",
    political_affiliation: "",
    income: "",
    net_worth: "",
    writing_sampling: "",
    parent_list: [],
    persona_children: ""
};

type FieldInfo = [string, string, boolean];

const publicFacingInfo: FieldInfo[] = [
    // ["first_name", "First Name", true],
    // ["middle_name", "Middle Name", true],
    // ["last_name", "Last Name", true],
    ["description", "Description", true],
    ["specificities", "Specificities", true],
    ["username", "Username", true],
    ["date_of_birth", "Date of Birth", true],
    ["facebook", "Facebook", true],
    ["linkedin", "LinkedIn", true],
    ["twitter", "Twitter", true],
    ["email", "Email", true],
    ["phone_number", "Phone Number", true],
    ["gender", "Gender", true],
    ["marital_status", "Marital Status", true],
    ["address", "Address", true],
    ["profession", "Profession", true],
    ["employer", "Employer", false],
    ["height", "Height", false],
    ["distinguishing_features", "Distinguishing Features", false],
    ["city", "City", false],
    ["state", "State", false],
    ["zipcode", "Zipcode", false],
    ["country", "Country", false]
];

const privateInfo: FieldInfo[] = [
    ["private_key", "Private Key", false],
    ["ssn", "SSN", false],
    ["religion", "Religion", false],
    ["political_affiliation", "Political Affiliation", false],
    ["income", "Income", false],
    ["net_worth", "Net Worth", false]
];

const lineageInfo: FieldInfo[] = [
    ["parent_list", "Parents", false],
    ["persona_children", "Children", false]
];

const removeFields = (fields: FieldInfo[], fieldsToRemove: string[]) => {
    return fields.filter(([name, label, isRequired]) => {
        return !fieldsToRemove.includes(name);
    });
};

const includeFields = (fields: FieldInfo[], fieldsToRemove: string[], all = false) => {
    if (all) return fields;
    return fields.filter(([name, label, isRequired]) => {
        return fieldsToRemove.includes(name);
    });
};

// TODO: move to models folder
type PersonaAttributeProps = {
    moveToNext?: () => void;
    moveToPrevious?: () => void;
};

const PersonaAttribute = ({ moveToNext, moveToPrevious }: PersonaAttributeProps) => {
    const { updatePersonaApi, createGeneralEntityApi, addMailgunDomainApi } = useHttpClient();
    const personaFormState: PersonaFormState = useSelector(
        (state: any) => state.persona.personaAttributesForm
    );
    const [isSaving, setIsSaving] = useState(false);
    const formikRef = useRef<FormikProps<any>>(null);
    const toast = useToast();
    const [isNotarizeModalActive, setIsNotarizeModalActive] = useState(false);
    const [isNotarizedInformationModalActive, setIsNotarizedInformationModalActive] =
        useState(false);
    const [isPictureLoading, setIsPictureLoading] = useState(false);
    const domainDisclosure = useDisclosure();
    const [workEmails, setWorkEmails] = useState<any[]>([]);

    // Create a ref for the Formik instance
    const genderOptions = [
        {
            label: "Male",
            value: "male"
        },
        {
            label: "Female",
            value: "female"
        }
    ];

    const maritalStatusOptions = [
        { label: "Single", value: "single" },
        { label: "Married", value: "married" },
        { label: "Divorced", value: "divorced" }
    ];

    const countryOptions = [{ label: "Lootverse", value: "lootverse" }];

    useEffect(() => {
        formikRef.current?.setValues({
            ...personaFormState,
            facebook: personaFormState.social?.facebook,
            twitter: personaFormState.social?.twitter,
            linkedin: personaFormState.social?.linkedin,
            employer: personaFormState.employers?.company_name,
            persona_children: ""
        });

        setWorkEmails(personaFormState?.work_emails || []);
    }, [personaFormState]);

    /**
     *
     * @param values
     * @param actions
     */

    const updatePersona = async (values, actions) => {
        const formData: any = {
            ...values
        };
        // Validattion
        if (formData.income && isNaN(formData.income)) {
            toast({
                title: "Income must be a number",
                status: "warning",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        if (formData.net_worth && isNaN(formData.net_worth)) {
            toast({
                title: "Net Worth must be a number",
                status: "warning",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        // if (formData.phone_number && isNaN(formData.phone_number)) {
        //     toast({
        //         title: "Phone Number must be a number",
        //         status: "warning",
        //         duration: 3000,
        //         isClosable: true,
        //     });
        //     return;
        // }

        if (formData.height && isNaN(formData.height)) {
            toast({
                title: "Height must be a number",
                status: "warning",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        // delete key props
        delete formData["personality_trait"];
        delete formData["knowledge"];
        delete formData["employer"];
        // Add socials
        formData.social = {
            facebook: values.facebook,
            linkedin: values.linkedin,
            twitter: values.twitter
        };
        // set employers data
        formData.employers = {
            company_name: values.employer
        };
        setIsSaving(true);
        /**
         * API CALLS
         */

        if (!personaFormState.id && personaFormState.is_general_entity === "yes") {
            createGeneralEntityApi(formData)
                .then(() => {
                    toast({
                        title: "Entity Created",
                        status: "success",
                        duration: 3000,
                        isClosable: true
                    });
                })
                .finally(() => {
                    actions.setSubmitting(false);
                    setIsSaving(false);
                });
        } else {
            updatePersonaApi(formData)
                .then(() => {
                    toast({
                        title: "Persona updated",
                        status: "success",
                        duration: 3000,
                        isClosable: true
                    });
                })
                .finally(() => {
                    actions.setSubmitting(false);
                    setIsSaving(false);
                });
        }
    };

    const addMailgunDomain = async (values: { label: string }, actions) => {
        try {
            const data = await addMailgunDomainApi(values.label, personaFormState.id);

            if (data) {
                domainDisclosure.onClose();
                const updatedEmails = Array.from(new Set([...workEmails, values.label]));
                setWorkEmails(updatedEmails);

                toast({
                    title: "Work Email Added Successfully",
                    status: "success"
                });
            }
        } catch (error) {
            toast({
                title: `Error in adding Work Email`,
                status: "error"
            });

            console.log("Error in addMailgunDomain:", error);
            domainDisclosure.onClose();
        }

        actions.setSubmitting(false);
    };

    const deleteWorkEmail = async (index) => {
        const updatedEmails = [...workEmails];
        updatedEmails.splice(index, 1);
        const data = await updatePersonaApi({
            id: personaFormState.id,
            work_emails: updatedEmails
        });

        if (data) {
            setWorkEmails(updatedEmails);

            toast({
                title: "Work Email Deleted",
                status: "success",
                duration: 3000,
                isClosable: true
            });
        } else {
            toast({
                title: "Error in deleting Work Email",
                status: "error"
            });
        }
    };

    return (
        <div className="pt-[10px] pl-[5px]">
            <div className="persona-preview border-b min-h-[100px] pb-4 mb-4 grid grid-cols-[auto_200px]">
                <div>
                    <div className="head mb-1 flex items-center gap-[10px]">
                        <p className="rotate-90">
                            <PiTreeStructure size={"24px"} />
                        </p>
                        <h1 className="text-[18px] font-medium text-gray-600 m-0">Genealogy</h1>
                    </div>
                    <p className="date text-slate-600 mb-2">
                        Fertilization Date: 23 August 2023 23:23:43 UTC (Earth), 2119 LCE (Lootian
                        Year)
                    </p>
                    <div className="name flex gap-1">
                        <p className="label">Name: </p>
                        <p className="value font-semibold">
                            {getPersonaFullName(personaFormState)}
                        </p>
                    </div>
                    <div className="name flex gap-1">
                        <p className="label">Parents: </p>
                        <p className="value font-semibold">
                            {(personaFormState.parent_list as PersonaModel[])?.reduce(
                                (displayString, parent, index) => {
                                    let returnString = displayString;
                                    if (typeof parent === "object" && parent !== null) {
                                        if (index !== 0) {
                                            returnString += ", ";
                                        }
                                        returnString += parent.first_name + " " + parent.last_name;
                                    }

                                    return returnString;
                                },
                                ""
                            )}
                        </p>
                    </div>
                    <div className="name flex gap-1">
                        <p className="label">Offspring: </p>
                        <p className="value font-semibold">{personaFormState.persona_children}</p>
                    </div>
                </div>
                <div className="photo-section relative">
                    <PersonaProfilePhoto
                        persona={personaFormState}
                        setIsPictureLoading={setIsPictureLoading}
                    />
                    {isPictureLoading && (
                        <div className="image-loading flex justify-center overflow-visible absolute top-0 left-0 w-full">
                            <div className="spinner-element h-[95px] w-[95px] rounded-full bg-black/[.6] flex justify-center items-center">
                                <Spinner color="white" style={{ width: "30px", height: "30px" }} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <p className="text-[13px]">
                Important. This is the birthing process of Arin. Only proceed if
                you understand the system. Note that once entered, the
                information whose fields are denoted with a * cannot be updated.
                You will have to "kill" the being and restart the process over.
            </p> */}

            <div className="">
                <Formik
                    initialValues={formInitialValues}
                    onSubmit={updatePersona}
                    innerRef={formikRef}
                >
                    {(formikProps) => (
                        <Form>
                            <div className="header">
                                <h2 className="mb-4 font-medium text-[16px]">
                                    Public Facing Information:
                                </h2>
                            </div>
                            <div className="flex">
                                {/* Public Facing Information */}
                                <div className="w-1/2 pr-3">
                                    {includeFields(
                                        publicFacingInfo,
                                        [
                                            "first_name",
                                            "middle_name",
                                            "last_name",
                                            "description",
                                            "specificities"
                                        ],
                                        personaFormState.is_general_entity !== "yes"
                                    )
                                        .slice(0, 10)
                                        .map(([name, label, isRequired]: FieldInfo, index) => (
                                            <Field
                                                key={`public-field-${index}`}
                                                name={name}
                                                validate={(value: any) => {
                                                    const limitedTypes = [
                                                        "description",
                                                        "specificities"
                                                    ];
                                                    if (isRequired && !value) {
                                                        return `Please insert the ${label.toLowerCase()}`;
                                                    }
                                                    if (
                                                        limitedTypes.includes(name) &&
                                                        value.length > 5000
                                                    ) {
                                                        return `Please limit the ${label.toLowerCase()} to 5000 characters`;
                                                    }
                                                }}
                                            >
                                                {({ field, form }) => (
                                                    <FormControl
                                                        className="mb-4"
                                                        mb={4}
                                                        isInvalid={
                                                            form.errors[name] && form.touched[name]
                                                        }
                                                    >
                                                        <div className="">
                                                            <div className="form-label mb-1  ">
                                                                <div className="label-text">
                                                                    {label}{" "}
                                                                    {isRequired ? (
                                                                        <span className="text-red-500">
                                                                            *
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {name === "gender" && (
                                                                <Select
                                                                    placeholder="Select Gender"
                                                                    options={genderOptions}
                                                                    value={genderOptions.find(
                                                                        (option) =>
                                                                            option.value ===
                                                                            field.value
                                                                    )}
                                                                    onChange={(option) =>
                                                                        form.setFieldValue(
                                                                            "gender",
                                                                            option?.value
                                                                        )
                                                                    }
                                                                    onBlur={field.onBlur}
                                                                    className="arin-react-select-container persona-select"
                                                                    classNamePrefix="arin-react-select"
                                                                />
                                                            )}
                                                            {name !== "gender" &&
                                                                name !== "marital_status" &&
                                                                name !== "country" && (
                                                                    <Input {...field} />
                                                                )}
                                                        </div>
                                                        {(name === "description" ||
                                                            name === "specificities") &&
                                                        !form.errors[name] ? (
                                                            <FormHelperText fontSize={"12px"}>
                                                                Max 5000 characters
                                                            </FormHelperText>
                                                        ) : (
                                                            <FormErrorMessage>
                                                                {form.errors[name]}
                                                            </FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                )}
                                            </Field>
                                        ))}
                                </div>
                                <div className="w-1/2 pr-3">
                                    {personaFormState.is_general_entity !== "yes" &&
                                        publicFacingInfo
                                            .slice(10)
                                            .map(([name, label, isRequired]: FieldInfo, index) => (
                                                <Field
                                                    key={`public-field-2-${index}`}
                                                    name={name}
                                                    validate={(value: any) => {
                                                        if (isRequired && !value) {
                                                            return `Please insert the ${label.toLowerCase()}`;
                                                        }
                                                    }}
                                                >
                                                    {({ field, form }) => (
                                                        <FormControl
                                                            className="h-[86px]"
                                                            mb={4}
                                                            isInvalid={
                                                                form.errors[name] &&
                                                                form.touched[name]
                                                            }
                                                        >
                                                            <div className="">
                                                                <div className="form-label mb-1  ">
                                                                    <div className="label-text">
                                                                        {label}{" "}
                                                                        {isRequired ? (
                                                                            <span className="text-red-500">
                                                                                *
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {name === "marital_status" && (
                                                                    <Select
                                                                        placeholder="Select status"
                                                                        options={
                                                                            maritalStatusOptions
                                                                        }
                                                                        value={maritalStatusOptions.find(
                                                                            (option) =>
                                                                                option.value ===
                                                                                field.value
                                                                        )}
                                                                        onChange={(option) =>
                                                                            form.setFieldValue(
                                                                                "marital_status",
                                                                                option?.value
                                                                            )
                                                                        }
                                                                        onBlur={field.onBlur}
                                                                        className="arin-react-select-container persona-select"
                                                                        classNamePrefix="arin-react-select"
                                                                    />
                                                                )}
                                                                {name === "country" && (
                                                                    <Select
                                                                        placeholder="Select country"
                                                                        options={countryOptions}
                                                                        value={countryOptions.find(
                                                                            (option) =>
                                                                                option.value ===
                                                                                field.value
                                                                        )}
                                                                        onChange={(option) =>
                                                                            form.setFieldValue(
                                                                                "country",
                                                                                option?.value
                                                                            )
                                                                        }
                                                                        onBlur={field.onBlur}
                                                                        className="arin-react-select-container persona-select"
                                                                        classNamePrefix="arin-react-select"
                                                                    />
                                                                )}
                                                                {name !== "gender" &&
                                                                    name !== "marital_status" &&
                                                                    name !== "country" && (
                                                                        <Input {...field} />
                                                                    )}
                                                            </div>
                                                            <FormErrorMessage>
                                                                {form.errors[name]}
                                                            </FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            ))}
                                </div>
                            </div>

                            {/* Private Information */}
                            {personaFormState.is_general_entity !== "yes" && (
                                <div className="flex mt-4">
                                    <div className="w-1/2 pr-3">
                                        <h2 className="mb-4 font-medium text-[16px]">
                                            Private Information:
                                        </h2>
                                        {privateInfo.map(
                                            ([name, label, isRequired]: FieldInfo, index) => (
                                                <Field
                                                    key={`private-field-${index}`}
                                                    name={name}
                                                    validate={(value: any) => {
                                                        if (isRequired && !value) {
                                                            return `Please insert the ${label.toLowerCase()}`;
                                                        }
                                                    }}
                                                >
                                                    {({ field, form }) => (
                                                        <FormControl
                                                            className="h-[86px]"
                                                            mb={3}
                                                            isInvalid={
                                                                form.errors[name] &&
                                                                form.touched[name]
                                                            }
                                                        >
                                                            <div className="">
                                                                <div className="form-label mb-1  ">
                                                                    <div className="label-text">
                                                                        {label}{" "}
                                                                        {isRequired ? (
                                                                            <span className="text-red-500">
                                                                                *
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <Input {...field} />
                                                            </div>
                                                            <FormErrorMessage
                                                                display="flex"
                                                                justifyContent="end"
                                                            >
                                                                {form.errors[name]}
                                                            </FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            )
                                        )}
                                    </div>

                                    <div className="w-1/2 pl-3 pb-4">
                                        <div className="pb-4">
                                            <h2 className="mb-[10px] font-semibold text-[16px]">
                                                Copyright Persona DNA
                                            </h2>
                                            <p className="text-slate-500 text-[13px] mb-4">
                                                We take an imprint of your persona’s knowledge,
                                                personality and building blocks (“Digital DNA”) and
                                                we anchor them in the blockchain. This notarization
                                                process is unique to your persona DNA and proves
                                                that it existed in that form at the time their
                                                Digital DNA’s snapshot were taken. It As your
                                                persona interacts more, their Digital DNA will
                                                change. Therefore, we recommend that you take a
                                                snapshot of the DNA regularly. Each copyright
                                                notarization costs US$10.
                                            </p>
                                            <div className="">
                                                <Button
                                                    onClick={() => setIsNotarizeModalActive(true)}
                                                    colorScheme="brand"
                                                    mb={3}
                                                >
                                                    Notarize
                                                </Button>
                                            </div>
                                            <Button
                                                colorScheme="blue"
                                                onClick={() =>
                                                    setIsNotarizedInformationModalActive(true)
                                                }
                                                size={"sm"}
                                                variant="ghost"
                                            >
                                                Past Notarization Information.
                                            </Button>
                                        </div>

                                        <div>
                                            <h2 className="mb-[10px] font-semibold text-[16px]">
                                                Persona Work Email Domains
                                            </h2>
                                            <p className="text-slate-500 text-[13px] mb-4">
                                                You can add any alias plus a domain as a work email
                                                to your persona but ensure the domain is authorized
                                                in your Mailgun account
                                            </p>

                                            {workEmails.length > 0 && (
                                                <div className="pb-4">
                                                    <Box
                                                        border="1px solid #CBD5E0"
                                                        borderRadius="md"
                                                        p="2"
                                                        display="flex"
                                                        flexWrap="wrap"
                                                        alignItems="flex-start"
                                                        minH="35px"
                                                        maxH="200px"
                                                        overflowY="auto"
                                                    >
                                                        {workEmails?.map((record, itemIndex) => (
                                                            <Tag
                                                                size="sm"
                                                                key={itemIndex}
                                                                borderRadius="5px"
                                                                fontSize="85%"
                                                                m="0.5"
                                                                variant="solid"
                                                                colorScheme="brand"
                                                            >
                                                                <TagLabel>{record}</TagLabel>
                                                                <TagCloseButton
                                                                    onClick={() =>
                                                                        deleteWorkEmail(itemIndex)
                                                                    }
                                                                />
                                                            </Tag>
                                                        ))}
                                                    </Box>
                                                </div>
                                            )}

                                            <div className="">
                                                <Button
                                                    onClick={() => domainDisclosure.onOpen()}
                                                    size="sm"
                                                    leftIcon={<FiPlus />}
                                                    colorScheme="brand"
                                                >
                                                    Add Domain
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="mt-4 persona-footer flex items-center px-[24px] h-[55px] justify-end">
                                <Button
                                    colorScheme="brand"
                                    isLoading={isSaving}
                                    type="submit"
                                    loadingText="Saving.."
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* Notarize */}
            <NotarizePersonaModal
                isOpen={isNotarizeModalActive}
                onClose={() => setIsNotarizeModalActive(false)}
            />
            <NotarizedInformationModal
                isOpen={isNotarizedInformationModalActive}
                onClose={() => setIsNotarizedInformationModalActive(false)}
            />

            {/* Modal to Create*/}
            <Modal isCentered isOpen={domainDisclosure.isOpen} onClose={domainDisclosure.onClose}>
                <ModalOverlay />
                <Formik initialValues={{ label: "", persona: "" }} onSubmit={addMailgunDomain}>
                    {(props) => (
                        <Form>
                            <ModalContent>
                                <ModalHeader>Add Persona Work Email</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <FormControl mb={6}>
                                        <p className="text-[14px] mb-[2px]">Work Email</p>
                                        <Field
                                            name="label"
                                            as={Input}
                                            placeholder="Enter alias plus the domain for the work email..."
                                        />
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button
                                        size="sm"
                                        colorScheme="brand"
                                        mr={3}
                                        type="submit"
                                        isLoading={props.isSubmitting}
                                        loadingText="Adding..."
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        size="sm"
                                        onClick={domainDisclosure.onClose}
                                        variant="outline"
                                    >
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default PersonaAttribute;
