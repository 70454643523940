import { Ref } from "react";

/**
 *
 * Scroll to bottom of view
 * It is important in chat mode to scroll to bottom so user sees current replies, but some cases like expanding replies,
 * Scrolling to bottom is not ideal
 */
export function scrollToBottom(containerRef: Ref<any>) {
    if (containerRef) {
        const container = (containerRef as any).current;

        if (container.scrollTop + container.clientHeight < container.scrollHeight) {
            setTimeout(
                () =>
                    container.scroll({
                        top: container.scrollHeight,
                        behavior: "smooth"
                    }),
                400
            );
        }
    }
}

export function isDOMElement(o: any) {
    return typeof HTMLElement === "object"
        ? o instanceof HTMLElement //DOM2
        : o && typeof o === "object" && true && o.nodeType === 1 && typeof o.nodeName === "string";
}
