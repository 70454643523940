import React from "react";
import { BsRobot } from "react-icons/bs";
import LoadingDots from "../LoadingDots";
import { formatCollaborateMessage } from "../../utils/collaborate/parser";
type BotChatProps = {
    loading?: boolean;
    message: string;
};
const BotChat = ({ loading, message }: BotChatProps) => {
    return (
        <div className="ai-entity-msg mb-[12px] bg-gray-50 border border-gray-200 pt-2 pb-3 px-3 rounded text-black mr-[24px]">
            <div className="grid gap-2 grid-cols-[35px_auto]">
                <div className="pt-[5px]">
                    <BsRobot size="28px" className="text-gray-600" />
                </div>
                <div className="">
                    <p className="">General AI Entity</p>

                    <p className="character-message mt-2 mb-1 text-slate-500">
                        {loading ? <LoadingDots size={5} /> : formatCollaborateMessage(message)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BotChat;
