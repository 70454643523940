import { ThreadModel } from "../../models/search.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThreadsSlice {
    isSearchLoading: boolean;
    history: string;
    replies: ThreadModel[];
}

const initialState: ThreadsSlice = {
    isSearchLoading: false,
    history: "",
    replies: []
};

const threadsSlice = createSlice({
    name: "threads",
    initialState,
    reducers: {
        setThreadLoadingStatus: (state, action: PayloadAction<boolean>) => {
            state.isSearchLoading = action.payload;
        },
        addThreadQuery: (state, action) => {
            state.replies = [...state.replies, action.payload];
        },
        updateLastThreadQuery: (state, action) => {
            state.replies = [...state.replies].map((response, index) => {
                if (
                    index === state.replies.length - 1 &&
                    response.question === action.payload.question
                ) {
                    return { ...response, ...action.payload };
                }
                return response;
            });
        },
        removeThreadQuery: (state, action: PayloadAction<ThreadModel>) => {
            state.replies = state.replies.filter(
                (response) => response.thread_id !== action.payload.thread_id
            );
        },
        removeLastThreadQuery: (state) => {
            state.replies = state.replies.slice(0, -1);
        },
        updateThreadQuery: (state, action: PayloadAction<ThreadModel>) => {
            state.replies = [...state.replies].map((response) => {
                if (response.thread_id === action.payload.thread_id) {
                    return action.payload;
                }
                return response;
            });
        },
        setThreadHistory: (state, action: PayloadAction<string>) => {
            state.history = action.payload;
        },
        clearThreadHistory: (state) => {
            state.history = "";
        }
    }
});

export const {
    addThreadQuery,
    removeLastThreadQuery,
    updateLastThreadQuery,
    updateThreadQuery,
    setThreadHistory,
    removeThreadQuery,
    clearThreadHistory,
    setThreadLoadingStatus
} = threadsSlice.actions;

export default threadsSlice.reducer;
