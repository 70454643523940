import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import {
    Box,
    Button,
    Tag,
    TagLabel,
    TagCloseButton,
    Switch,
    useToast,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    useDisclosure,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import { useHttpClient } from "../utils/http.utils";
import { Formik, Form } from "formik";
import { BsPlus } from "react-icons/bs";

type PerceptorItem = {
    id: string;
    listener: string;
    restriction?: string[];
    skills: string[];
    collab: string;
    index: number;
    fetch_complete_email_content?: boolean;
    receiver_whitelist?: string[];
    whitelisted_phone_list?: string[];
    domain?: string;
    action?: string;
    filter?: string;
    filter_values?: string[];
    action_values?: string[];
    route_id?: string;
    campaign?: string;
};

interface SkillListProps {
    skills?: any[];
    perceptors?: any[];
    lists?: any[];
    personaId?: string;
    updatePerceptor?: () => void;
    personaWorkEmails?: any[];
}

const PerceptorList: React.FC<SkillListProps> = (props: SkillListProps) => {
    // Use states
    const [listener, setListener] = useState<string>("");
    const [enableCollab, setEnableCollab] = useState<string>("no");
    const [skills, setSkills] = useState<any[]>([]);
    const [personaPerceptors, setPersonaPerceptors] = useState<PerceptorItem[]>([]);
    const [emails, setEmails] = useState<string[]>([]);
    const [receiverEmails, setReceiverEmails] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState<boolean[]>([]);
    const [isDeleteLoadingList, setIsDeleteLoadingList] = useState<boolean[]>([]);
    const [isFullEmailContent, setIsFullEmailContent] = useState<boolean>(false);
    const [textareaValues, setTextareaValues] = useState({});
    const [receiverTextareaValues, setReceiverTextareaValues] = useState({});
    const [filterTextareaValues, setFilterTextareaValues] = useState({});
    const [actionTextareaValues, setActionTextareaValues] = useState({});
    const [inputValue, setInputValue] = useState("");
    const [receiverInputValue, setReceiverInputValue] = useState("");
    const [filterInputValue, setFilterInputValue] = useState("");
    const [actionInputValue, setActionInputValue] = useState("");
    const [filterValues, setFilterValues] = useState<string[]>([]);
    const [actionValues, setActionValues] = useState<string[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [twilioListener, setTwilioListener] = useState("");
    const [mailgunListener, setMailgunListener] = useState("");
    const [filters, setFilters] = useState("");
    const [actions, setActions] = useState("");
    const [workEmailFilters, setWorkEmailFilters] = useState("");

    // Add state variables for the phone numbers
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
    const [phoneNumberInputValue, setPhoneNumberInputValue] = useState("");
    const [phoneValues, setPhoneValues] = useState({});

    // Add a ref for the phone number textarea
    const phoneNumberTextareaRef = useRef(null);

    // other hooks
    const perceptorFormDisc = useDisclosure();

    const listenerOptions = props.perceptors?.map((perceptor) => ({
        value: perceptor.id,
        label: perceptor.perceptor_code
    }));

    const workEmailOptions = props.personaWorkEmails?.map((email) => ({
        value: email,
        label: email
    }));

    // twilio functions
    const handlePhoneNumberInputChange = (event) => {
        setPhoneNumberInputValue(event.target.value);
    };

    const handlePhoneNumberKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handlePhoneNumberInput(phoneNumberInputValue.trim());
        }
    };

    // Add a function to handle the phone number input
    const handlePhoneNumberInput = (phoneNumber) => {
        if (phoneNumber && !phoneNumbers.includes(phoneNumber)) {
            setPhoneNumbers([...phoneNumbers, phoneNumber]);
        }
        setPhoneNumberInputValue("");
    };

    // Add a function to remove a phone number
    const removePhoneNumber = (index) => {
        setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const twilioPerceptor = props.perceptors?.find(
            (perceptor) => perceptor.perceptor_code === "twilio"
        );
        if (twilioPerceptor) {
            setTwilioListener(twilioPerceptor.id);
        }
        const mailgunPerceptor = props.perceptors?.find(
            (perceptor) => perceptor.perceptor_code === "mailgun"
        );
        if (mailgunPerceptor) {
            setMailgunListener(mailgunPerceptor.id);
        }
        setActions("webhook");
    }, [props.perceptors]);

    const toast = useToast();

    const {
        createOrUpdatePersonaUserPerceptor,
        deletePersonaUserPerceptor,
        createTwilioPersonaUserPerceptorApi,
        updateTwilioPersonaUserPerceptorApi
    } = useHttpClient();

    useEffect(() => {
        if (props.lists) {
            const apiData = props.lists;

            const transformedData = apiData.flatMap((item) => {
                const emailConfigs =
                    item.perceptor_setting.email_config_list?.map((emailConfig, index) => {
                        return {
                            id: item.id,
                            listener: item.user_perceptor.id,
                            restriction: emailConfig.email_wildcard_list,
                            skills: emailConfig.collaboration_app_group_flow,
                            collab: emailConfig.force_collab,
                            fetch_complete_email_content:
                                emailConfig.fetch_complete_email_content || false,
                            index: index,
                            receiver_whitelist: emailConfig.receiver_wildcard_list || [],
                            domain: emailConfig.domain,
                            action: emailConfig.action,
                            filter: emailConfig.filter,
                            filter_values: emailConfig.filter_values || [],
                            action_values: emailConfig.action_values || [],
                            route_id: emailConfig.route_id,
                            campaign: emailConfig.campaign
                        };
                    }) || [];

                const phoneConfigs =
                    item.perceptor_setting.phone_config_list?.map((phoneConfig, index) => {
                        return {
                            id: item.id,
                            listener: item.user_perceptor.id,
                            skills: phoneConfig.collaboration_app_group_flow,
                            collab: phoneConfig.force_collab,
                            index: index,
                            whitelisted_phone_list: phoneConfig.whitelisted_phone_list || [],
                            campaign: phoneConfig.campaign
                        };
                    }) || [];

                return [...emailConfigs, ...phoneConfigs];
            });

            setPersonaPerceptors(transformedData);
            setIsLoadingList(Array(transformedData.length).fill(false));
            setIsDeleteLoadingList(Array(transformedData.length).fill(false));
        }
    }, [props.lists]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleReceiverInputChange = (event) => {
        setReceiverInputValue(event.target.value);
    };

    const handleFilterInputChange = (event) => {
        setFilterInputValue(event.target.value);
    };

    const handleActionInputChange = (event) => {
        setActionInputValue(event.target.value);
    };

    const validateEmail = (email: string): boolean => {
        email = email.toLowerCase();
        const re1 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const re2 = /^(\*|[a-z0-9._%+-]+)@(\*|[a-z]+)\.(\*|[a-z]+|\*)$|^\*$/;

        if (re1.test(email)) {
            return re1.test(email);
        } else {
            return re2.test(email);
        }
    };

    const skillsOptions = props.skills?.map((skill) => ({
        value: skill.value,
        label: skill.label
    }));

    const handleEmailInput = (email) => {
        if (email && validateEmail(email) && !emails.includes(email)) {
            setEmails([...emails, email]);
            setInputValue("");
        }
    };

    const handleReceiverEmailInput = (email) => {
        if (email && validateEmail(email) && !receiverEmails.includes(email)) {
            setReceiverEmails([...receiverEmails, email]);
            setReceiverInputValue("");
        }
    };

    const handleFilterInput = (filter) => {
        if (filter && !filterValues.includes(filter)) {
            setFilterValues([...filterValues, filter]);
            setFilterInputValue("");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            handleEmailInput(inputValue.trim());
        }
    };

    const handleReceiverKeyDown = (event) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            handleReceiverEmailInput(receiverInputValue.trim());
        }
    };

    const handleFilterKeyDown = (event) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            handleFilterInput(filterInputValue.trim());
        }
    };

    const handleActionInput = (action) => {
        if (action && !actionValues.includes(action)) {
            setActionValues([...actionValues, action]);
            setActionInputValue("");
        }
    };

    const handleActionKeyDown = (event) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            handleActionInput(actionInputValue.trim());
        }
    };

    const handleInputUpdate = (event, itemIndex) => {
        const newValue = event.target.value;
        setTextareaValues((prevValues) => ({
            ...prevValues,
            [itemIndex]: newValue // Update only the value of the textarea being changed
        }));
    };

    const handlePhoneUpdate = (event, itemIndex) => {
        const newValue = event.target.value;
        setPhoneValues((prevValues) => ({
            ...prevValues,
            [itemIndex]: newValue // Update only the value of the textarea being changed
        }));
    };

    const handleReceiverInputUpdate = (event, itemIndex) => {
        const newValue = event.target.value;
        setReceiverTextareaValues((prevValues) => ({
            ...prevValues,
            [itemIndex]: newValue // Update only the value of the textarea being changed
        }));
    };

    const handleFilterInputUpdate = (event, itemIndex) => {
        const newValue = event.target.value;
        setFilterTextareaValues((prevValues) => ({
            ...prevValues,
            [itemIndex]: newValue // Update only the value of the textarea being changed
        }));
    };

    const handleActionInputUpdate = (event, itemIndex) => {
        const newValue = event.target.value;
        setActionTextareaValues((prevValues) => ({
            ...prevValues,
            [itemIndex]: newValue // Update only the value of the textarea being changed
        }));
    };

    const handleEditKeyDown = (event, index) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            const inputValue = textareaValues[index] || ""; // Get current input value
            let email = inputValue.trim();

            if (
                email &&
                validateEmail(email) &&
                !personaPerceptors[index].restriction?.includes(email)
            ) {
                // Update the restrictions for the specific item
                const updatedPerceptors = personaPerceptors.map((perceptor, perceptorIndex) => {
                    if (index === perceptorIndex) {
                        return {
                            ...perceptor,
                            restriction: [...(perceptor.restriction || []), email]
                        };
                    }
                    return perceptor;
                });

                setPersonaPerceptors(updatedPerceptors); // Update the state with the new perceptors array
                // Reset the input value for the current textarea
                setTextareaValues((prevValues) => ({
                    ...prevValues,
                    [index]: ""
                }));
            }
        }
    };

    const handleEditPhone = (event, index) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            const inputValue = phoneValues[index] || ""; // Get current input value
            let phoneNumber = inputValue.trim();

            if (
                phoneNumber &&
                !personaPerceptors[index].whitelisted_phone_list?.includes(phoneNumber)
            ) {
                // Update the whitelisted_phone_list for the specific item
                const updatedPerceptors = personaPerceptors.map((perceptor, perceptorIndex) => {
                    if (index === perceptorIndex) {
                        return {
                            ...perceptor,
                            whitelisted_phone_list: [
                                ...(perceptor.whitelisted_phone_list || []),
                                phoneNumber
                            ]
                        };
                    }
                    return perceptor;
                });

                setPersonaPerceptors(updatedPerceptors); // Update the state with the new perceptors array
                // Reset the input value for the current textarea
                setPhoneValues((prevValues) => ({
                    ...prevValues,
                    [index]: ""
                }));
            }
        }
    };

    const handleEditReceiverKeyDown = (event, index) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            const receiverInputValue = receiverTextareaValues[index] || ""; // Get current input value
            let email = receiverInputValue.trim();

            if (
                email &&
                validateEmail(email) &&
                !personaPerceptors[index].receiver_whitelist?.includes(email)
            ) {
                // Update the restrictions for the specific item
                const updatedPerceptors = personaPerceptors.map((perceptor, perceptorIndex) => {
                    if (index === perceptorIndex) {
                        return {
                            ...perceptor,
                            receiver_whitelist: [...(perceptor.receiver_whitelist || []), email]
                        };
                    }
                    return perceptor;
                });

                setPersonaPerceptors(updatedPerceptors); // Update the state with the new perceptors array
                // Reset the input value for the current textarea
                setReceiverTextareaValues((prevValues) => ({
                    ...prevValues,
                    [index]: ""
                }));
            }
        }
    };

    const handleEditFilterKeyDown = (event, index) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            const filterInputValue = filterTextareaValues[index] || ""; // Get current input value
            let filter = filterInputValue.trim();

            if (filter && !personaPerceptors[index].filter_values?.includes(filter)) {
                // Update the restrictions for the specific item
                const updatedPerceptors = personaPerceptors.map((perceptor, perceptorIndex) => {
                    if (index === perceptorIndex) {
                        return {
                            ...perceptor,
                            filter_values: [...(perceptor.filter_values || []), filter]
                        };
                    }
                    return perceptor;
                });

                setPersonaPerceptors(updatedPerceptors); // Update the state with the new perceptors array
                // Reset the input value for the current textarea
                setFilterTextareaValues((prevValues) => ({
                    ...prevValues,
                    [index]: ""
                }));
            }
        }
    };

    const handleEditActionKeyDown = (event, index) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault();
            const actionInputValue = actionTextareaValues[index] || "";
            let action = actionInputValue.trim();

            if (action && !personaPerceptors[index].action_values?.includes(action)) {
                const updatedPerceptors = personaPerceptors.map((perceptor, perceptorIndex) => {
                    if (index === perceptorIndex) {
                        return {
                            ...perceptor,
                            action_values: [...(perceptor.action_values || []), action]
                        };
                    }
                    return perceptor;
                });

                setPersonaPerceptors(updatedPerceptors);
                setActionTextareaValues((prevValues) => ({
                    ...prevValues,
                    [index]: ""
                }));
            }
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to auto or initial before calculating
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = `${scrollHeight}px`;
        }
    }, []);

    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const removeReceiverEmail = (index) => {
        setReceiverEmails(receiverEmails.filter((_, i) => i !== index));
    };

    const removeFilterValue = (index) => {
        setFilterValues(filterValues.filter((_, i) => i !== index));
    };

    const removeActionValue = (index) => {
        setActionValues(actionValues.filter((_, i) => i !== index));
    };

    const forceCollab = () => {
        if (enableCollab === "no") {
            setEnableCollab("yes");
        } else {
            setEnableCollab("no");
        }
    };

    const toggleFullEmailContent = () => {
        isFullEmailContent ? setIsFullEmailContent(false) : setIsFullEmailContent(true);
    };

    const savePerceptor = async (index, itemIndex) => {
        setIsLoadingList(
            isLoadingList.map((isLoading, loadingIndex) =>
                loadingIndex === index ? true : isLoading
            )
        );

        const perceptorToSave = personaPerceptors[index];

        try {
            if (!props.personaId) {
                throw new Error("persona id is not defined");
            } else {
                if (perceptorToSave.skills.length === 0 || perceptorToSave.listener === "") {
                    toast({
                        title: `Fill in the listener and skills inputs`,
                        status: "error"
                    });
                } else {
                    let data = null;

                    if (perceptorToSave.listener !== twilioListener) {
                        data = await createOrUpdatePersonaUserPerceptor(
                            props.personaId,
                            perceptorToSave.listener,
                            itemIndex + 1,
                            {
                                email_wildcard_list: perceptorToSave.restriction,
                                collaboration_app_group_flow: perceptorToSave.skills,
                                force_collab: perceptorToSave.collab,
                                fetch_complete_email_content:
                                    perceptorToSave.fetch_complete_email_content,
                                receiver_wildcard_list: perceptorToSave.receiver_whitelist,
                                ...(perceptorToSave.listener === mailgunListener && {
                                    domain: perceptorToSave.domain,
                                    action: perceptorToSave.action,
                                    filter: perceptorToSave.filter,
                                    filter_values: perceptorToSave.filter_values,
                                    action_values: perceptorToSave.action_values,
                                    route_id: perceptorToSave.route_id
                                })
                            },
                            perceptorToSave.listener === mailgunListener
                        );
                    } else {
                        data = await updateTwilioPersonaUserPerceptorApi(
                            perceptorToSave.id,
                            perceptorToSave.index + 1,
                            {
                                phone_config_list: {
                                    whitelisted_phone_list: perceptorToSave.whitelisted_phone_list,
                                    collaboration_app_group_flow: perceptorToSave.skills,
                                    force_collab: perceptorToSave.collab
                                }
                            }
                        );
                    }

                    if (data) {
                        toast({
                            title: "Persona User Perceptor has been updated successfully",
                            status: "success"
                        });
                    }
                }
            }
        } catch (error) {
            toast({
                title: `Error in saving Persona User Perceptor`,
                status: "error"
            });
            console.log("Error in createOrUpdatePersonaUserPerceptor:", error);
        }
        setIsLoadingList(
            isLoadingList.map((isLoading, loadingIndex) =>
                loadingIndex === index ? false : isLoading
            )
        );
        perceptorFormDisc.onClose();
    };

    const deletePerceptor = async (index, perceptorId, itemIndex) => {
        setIsDeleteLoadingList(
            isDeleteLoadingList.map((isLoading, loadingIndex) =>
                loadingIndex === index ? true : isLoading
            )
        );

        try {
            const data = await deletePersonaUserPerceptor(perceptorId, itemIndex + 1);

            if (data.success) {
                const listenerToRemove = personaPerceptors[index].listener;

                const updatedPerceptors = personaPerceptors
                    .filter((_, perceptorIndex) => perceptorIndex !== index)
                    .map((perceptor, updatedIndex, array) => {
                        if (perceptor.listener === listenerToRemove) {
                            const sameListenerCount = array
                                .slice(0, updatedIndex)
                                .filter((p) => p.listener === listenerToRemove).length;
                            return {
                                ...perceptor,
                                index: sameListenerCount
                            };
                        }
                        return perceptor;
                    });

                setPersonaPerceptors(updatedPerceptors);

                toast({
                    title: "Persona User Perceptor has been deleted!",
                    status: "success"
                });
            }
        } catch (error) {
            toast({
                title: `Error in deleting Persona User Perceptor`,
                status: "error"
            });
            console.log("Error in deletePersonaUserPerceptor:", error);
        }
        setIsDeleteLoadingList(
            isDeleteLoadingList.map((isLoading, loadingIndex) =>
                loadingIndex === index ? false : isLoading
            )
        );
    };

    const editEmail = (perceptorIndex, emailIndex) => {
        const updatedPerceptors = [...personaPerceptors];
        updatedPerceptors[perceptorIndex].restriction?.splice(emailIndex, 1);
        setPersonaPerceptors(updatedPerceptors);
    };

    const editNumber = (perceptorIndex, phoneNumberIndex) => {
        const updatedPerceptors = [...personaPerceptors];
        updatedPerceptors[perceptorIndex].whitelisted_phone_list?.splice(phoneNumberIndex, 1);
        setPersonaPerceptors(updatedPerceptors);
    };

    const editReceiverEmail = (perceptorIndex, emailIndex) => {
        const updatedPerceptors = [...personaPerceptors];
        updatedPerceptors[perceptorIndex].receiver_whitelist?.splice(emailIndex, 1);
        setPersonaPerceptors(updatedPerceptors);
    };

    const editFilterValue = (perceptorIndex, index) => {
        const updatedPerceptors = [...personaPerceptors];
        updatedPerceptors[perceptorIndex].filter_values?.splice(index, 1);
        setPersonaPerceptors(updatedPerceptors);
    };

    const editActionValue = (perceptorIndex, index) => {
        const updatedPerceptors = [...personaPerceptors];
        updatedPerceptors[perceptorIndex].action_values?.splice(index, 1);
        setPersonaPerceptors(updatedPerceptors);
    };

    const initialValues = {
        listener: "",
        forceCollab: false,
        fullEmailContent: false,
        skills: "",
        restrictions: ""
    };

    const validate = (values) => {
        const errors = {};
        // Add your validation logic here
        return errors;
    };

    // Define your form submit handler
    const onSubmit = async (values, { setSubmitting }) => {
        const skillValues = skills.map((skill) => skill.value);

        try {
            if (!props.personaId) {
                setSubmitting(false);
                throw new Error("persona id is not defined");
            } else {
                if (skillValues.length === 0 || listener === "") {
                    toast({
                        title: `Fill in the listener and skills inputs`,
                        status: "error"
                    });
                    setSubmitting(false);
                } else {
                    if (listener === twilioListener) {
                        const newPersonaPerceptor = await createTwilioPersonaUserPerceptorApi(
                            props.personaId,
                            listener,
                            {
                                phone_config_list: {
                                    whitelisted_phone_list: phoneNumbers,
                                    collaboration_app_group_flow: skillValues,
                                    force_collab: enableCollab
                                }
                            }
                        );

                        if (newPersonaPerceptor) {
                            setPersonaPerceptors((prev) => {
                                const twilioListenerCount = prev.filter(
                                    (perceptor) => perceptor.listener === twilioListener
                                ).length;

                                return [
                                    ...prev,
                                    {
                                        id: newPersonaPerceptor.persona_user_perceptor_id,
                                        listener,
                                        whitelisted_phone_list: phoneNumbers,
                                        skills: skillValues,
                                        collab: enableCollab,
                                        index: twilioListenerCount
                                    }
                                ];
                            });

                            if (typeof props.updatePerceptor === "function") {
                                props.updatePerceptor();
                            }

                            setEnableCollab("no");
                            setEmails([]);
                            setSkills([]);
                            setListener("");
                            setIsFullEmailContent(false);
                            setReceiverEmails([]);
                            setPhoneNumbers([]);

                            toast({
                                title: "Persona User Perceptor has been added successfully!",
                                status: "success"
                            });

                            setSubmitting(false);
                            // hide modal
                            perceptorFormDisc.onClose();
                        }
                    } else {
                        if (listener === mailgunListener && workEmailFilters.length <= 0) {
                            toast({
                                title: `Select the domain email to be used`,
                                status: "error"
                            });
                            setSubmitting(false);

                            perceptorFormDisc.onClose();
                        } else {
                            const newPersonaPerceptor = await createOrUpdatePersonaUserPerceptor(
                                props.personaId,
                                listener,
                                0,
                                {
                                    email_wildcard_list: emails,
                                    collaboration_app_group_flow: skillValues,
                                    force_collab: enableCollab,
                                    fetch_complete_email_content: isFullEmailContent,
                                    receiver_wildcard_list: receiverEmails,
                                    ...(listener === mailgunListener && {
                                        filter: filters,
                                        action: actions,
                                        domain: workEmailFilters,
                                        filter_values: filterValues,
                                        action_values: actionValues
                                    })
                                },
                                listener === mailgunListener
                            );

                            if (newPersonaPerceptor) {
                                setPersonaPerceptors((prev) => [
                                    ...prev,
                                    {
                                        id: newPersonaPerceptor.persona_user_perceptor_id,
                                        listener,
                                        restriction: emails,
                                        skills: skillValues,
                                        collab: enableCollab,
                                        index: newPersonaPerceptor.index,
                                        fetch_complete_email_content: isFullEmailContent,
                                        receiver_whitelist: receiverEmails,
                                        filter: filters,
                                        action: actions,
                                        domain: workEmailFilters,
                                        filter_values: filterValues,
                                        action_values: actionValues
                                    }
                                ]);

                                if (typeof props.updatePerceptor === "function") {
                                    props.updatePerceptor();
                                }

                                toast({
                                    title: "Persona User Perceptor has been added successfully!",
                                    status: "success"
                                });

                                setSubmitting(false);
                                // hide modal
                                perceptorFormDisc.onClose();
                            }
                        }
                        setEnableCollab("no");
                        setEmails([]);
                        setSkills([]);
                        setListener("");
                        setIsFullEmailContent(false);
                        setReceiverEmails([]);
                        setFilters("");
                        setActions("webhook");
                        setWorkEmailFilters("");
                        setFilterValues([]);
                        setActionValues([]);
                    }
                }
            }
        } catch (error) {
            setSubmitting(false);

            toast({
                title: `Error in creating Persona User Perceptor`,
                status: "error"
            });
            console.log("Error in createPersonaUserPerceptor:", error);
            perceptorFormDisc.onClose();
        }
    };

    const filtersOptions = [
        {
            value: "subject",
            label: "Match Subject"
        },
        {
            value: "from",
            label: "Match Sender"
        }
    ];

    const actionOptions = [
        {
            value: "webhook",
            label: "Forward To Webhook"
        },
        {
            value: "email",
            label: "Forward to Email"
        }
    ];

    return (
        <Box className="space-y-4 mt-10">
            <div className="flex justify-between items-center border-b px-4 pb-2">
                <h1>Perceptors</h1>
                <div>
                    <Button
                        variant="solid"
                        fontSize="14px"
                        colorScheme="brand"
                        size="sm"
                        onClick={perceptorFormDisc.onOpen}
                    >
                        <BsPlus />
                        Add New
                    </Button>
                </div>
            </div>

            {personaPerceptors.map((item, index) => (
                <Box key={index} className="py-3 border rounded-lg bg-neutral-50">
                    <div className="grid grid-cols-2">
                        <div className="col-1 px-4 flex flex-col gap-4">
                            {/* Listener Dropdown */}
                            <Box className="flex flex-col" style={{ flexBasis: "18%" }}>
                                <p className="">Listener</p>
                                <Select
                                    options={listenerOptions}
                                    value={listenerOptions?.find(
                                        (option) => option.value === item.listener
                                    )}
                                    onChange={(selectedOption) => {
                                        const updatedPerceptors = personaPerceptors.map(
                                            (perceptor, perceptorIndex) => {
                                                if (index === perceptorIndex) {
                                                    return {
                                                        ...perceptor,
                                                        listener: selectedOption?.value
                                                    };
                                                }
                                                return perceptor;
                                            }
                                        );
                                        setPersonaPerceptors(updatedPerceptors);
                                    }}
                                    placeholder="Choose perceptor"
                                    className="arin-react-select-container "
                                    classNamePrefix="arin-react-select"
                                    isDisabled
                                    // styles={selectStyles}
                                />
                            </Box>

                            {/* Restriction Input */}
                            {item.listener !== twilioListener &&
                                item.listener !== mailgunListener && (
                                    <Box className="flex flex-col" style={{ flexBasis: "18%" }}>
                                        <Box className="flex flex-col">
                                            <p className="">Restrictions</p>
                                            <Box
                                                border="1px solid #CBD5E0"
                                                borderRadius="md"
                                                p="2"
                                                display="flex"
                                                flexWrap="wrap"
                                                alignItems="flex-start"
                                                minH="35px"
                                                maxH="200px"
                                                overflowY="auto"
                                            >
                                                {item.restriction?.map((email, emailIndex) => (
                                                    <Tag
                                                        size="sm"
                                                        key={emailIndex}
                                                        borderRadius="5px"
                                                        fontSize="85%"
                                                        m="0.5"
                                                        variant="solid"
                                                        colorScheme="brand"
                                                    >
                                                        <TagLabel>{email}</TagLabel>
                                                        <TagCloseButton
                                                            disabled={item.campaign ? true : false}
                                                            onClick={() =>
                                                                editEmail(index, emailIndex)
                                                            }
                                                        />
                                                    </Tag>
                                                ))}
                                                <textarea
                                                    ref={textareaRef}
                                                    rows={1}
                                                    disabled={item.campaign ? true : false}
                                                    value={textareaValues[index] || ""}
                                                    onChange={(e) => handleInputUpdate(e, index)}
                                                    onKeyDown={(e) => handleEditKeyDown(e, index)}
                                                    placeholder={
                                                        item.restriction?.length === 0
                                                            ? "Restrictions, if any"
                                                            : ""
                                                    }
                                                    style={{
                                                        flexGrow: 1,
                                                        border: "none",
                                                        outline: "none",
                                                        resize: "none",
                                                        overflow: "hidden",
                                                        background: "transparent",
                                                        padding: "0",
                                                        margin: "0 1px"
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}

                            {/* Skills Dropdown */}
                            <Box className="flex flex-col" style={{ flexBasis: "32%" }}>
                                <p className="">Skill</p>
                                <Select
                                    isMulti
                                    isClearable={false}
                                    name="perceptor_skills"
                                    options={skillsOptions}
                                    isDisabled={item.campaign ? true : false}
                                    value={skillsOptions?.filter((option) =>
                                        item.skills?.includes(option.value)
                                    )}
                                    onChange={(selectedOptionList) => {
                                        const updatedPerceptors = personaPerceptors.map(
                                            (perceptor, perceptorIndex) => {
                                                if (index === perceptorIndex) {
                                                    return {
                                                        ...perceptor,
                                                        skills: selectedOptionList.map(
                                                            (selectedOption) => selectedOption.value
                                                        )
                                                    };
                                                }
                                                return perceptor;
                                            }
                                        );
                                        setPersonaPerceptors(updatedPerceptors);
                                    }}
                                    placeholder="Add skill to engage when action is detected"
                                    className="arin-react-select-container "
                                    classNamePrefix="arin-react-select"
                                />
                            </Box>
                        </div>

                        <div className="col-2 px-4 flex flex-col gap-4">
                            {/* Phone number Input */}
                            {item.listener === twilioListener && (
                                <Box className="flex flex-col" style={{ flexBasis: "18%" }}>
                                    <Box className="flex flex-col">
                                        <p className="">Phone Number Whitelist</p>
                                        <Box
                                            border="1px solid #CBD5E0"
                                            borderRadius="md"
                                            p="2"
                                            display="flex"
                                            flexWrap="wrap"
                                            alignItems="flex-start"
                                            minH="35px"
                                            maxH="200px"
                                            overflowY="auto"
                                        >
                                            {item.whitelisted_phone_list?.map(
                                                (phoneNumber, numberIndex) => (
                                                    <Tag
                                                        size="sm"
                                                        key={numberIndex}
                                                        borderRadius="5px"
                                                        fontSize="85%"
                                                        m="0.5"
                                                        variant="solid"
                                                        colorScheme="brand"
                                                    >
                                                        <TagLabel>{phoneNumber}</TagLabel>
                                                        <TagCloseButton
                                                            disabled={item.campaign ? true : false}
                                                            onClick={() =>
                                                                editNumber(index, numberIndex)
                                                            }
                                                        />
                                                    </Tag>
                                                )
                                            )}
                                            <textarea
                                                ref={phoneNumberTextareaRef}
                                                rows={1}
                                                value={phoneValues[index] || ""}
                                                disabled={item.campaign ? true : false}
                                                onChange={(e) => handlePhoneUpdate(e, index)}
                                                onKeyDown={(e) => handleEditPhone(e, index)}
                                                placeholder={
                                                    item.whitelisted_phone_list?.length === 0
                                                        ? "Whitelist phone number"
                                                        : ""
                                                }
                                                style={{
                                                    flexGrow: 1,
                                                    border: "none",
                                                    outline: "none",
                                                    resize: "none",
                                                    overflow: "hidden",
                                                    background: "transparent",
                                                    padding: "0",
                                                    margin: "0 1px"
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            {item.listener !== twilioListener &&
                                item.listener !== mailgunListener && (
                                    <Box className="flex flex-col">
                                        <p className="">Receiver Email(s)</p>
                                        <Box
                                            border="1px solid #CBD5E0"
                                            borderRadius="md"
                                            p="2"
                                            display="flex"
                                            flexWrap="wrap"
                                            alignItems="flex-start"
                                            minH="35px"
                                            maxH="200px"
                                            overflowY="auto"
                                        >
                                            {item.receiver_whitelist?.map((email, emailIndex) => (
                                                <Tag
                                                    size="sm"
                                                    key={emailIndex}
                                                    borderRadius="5px"
                                                    fontSize="85%"
                                                    m="0.5"
                                                    variant="solid"
                                                    colorScheme="brand"
                                                >
                                                    <TagLabel>{email}</TagLabel>
                                                    <TagCloseButton
                                                        disabled={item.campaign ? true : false}
                                                        onClick={() =>
                                                            editReceiverEmail(index, emailIndex)
                                                        }
                                                    />
                                                </Tag>
                                            ))}
                                            <textarea
                                                ref={textareaRef}
                                                rows={1}
                                                disabled={item.campaign ? true : false}
                                                value={receiverTextareaValues[index] || ""}
                                                onChange={(e) =>
                                                    handleReceiverInputUpdate(e, index)
                                                }
                                                onKeyDown={(e) =>
                                                    handleEditReceiverKeyDown(e, index)
                                                }
                                                placeholder={
                                                    item.receiver_whitelist?.length === 0
                                                        ? "Whitelist receiver email"
                                                        : ""
                                                }
                                                style={{
                                                    flexGrow: 1,
                                                    border: "none",
                                                    outline: "none",
                                                    resize: "none",
                                                    overflow: "hidden",
                                                    background: "transparent",
                                                    padding: "0",
                                                    margin: "0 1px"
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )}

                            {item.listener === mailgunListener && (
                                <div className="">
                                    <p className="mb-1">Domain Email</p>
                                    <Select
                                        options={workEmailOptions}
                                        value={
                                            workEmailOptions?.find(
                                                (option) => option.value === item.domain
                                            ) || "Choose persona's work email"
                                        }
                                        onChange={(selectedOption) => {
                                            const updatedPerceptors = personaPerceptors.map(
                                                (perceptor, perceptorIndex) => {
                                                    if (index === perceptorIndex) {
                                                        return {
                                                            ...perceptor,
                                                            domain:
                                                                typeof selectedOption === "string"
                                                                    ? selectedOption
                                                                    : selectedOption?.value
                                                        };
                                                    }
                                                    return perceptor;
                                                }
                                            );
                                            setPersonaPerceptors(updatedPerceptors);
                                        }}
                                        // styles={selectStyles}
                                        placeholder="Choose persona's work email"
                                        className="arin-react-select-container "
                                        classNamePrefix="arin-react-select"
                                    />
                                </div>
                            )}
                            <div className="pt-3">
                                {/* Collab checkbox*/}
                                <Box className="mb-4">
                                    <div className="flex items-center justify-between">
                                        <p className="">Force collab</p>
                                        <Switch
                                            // id="collab-alerts"
                                            isChecked={item.collab === "yes"}
                                            disabled={item.campaign ? true : false}
                                            onChange={() => {
                                                const newCollabValue =
                                                    item.collab === "yes" ? "no" : "yes";

                                                const updatedPerceptors = personaPerceptors.map(
                                                    (perceptor, perceptorIndex) => {
                                                        if (index === perceptorIndex) {
                                                            return {
                                                                ...perceptor,
                                                                collab: newCollabValue
                                                            };
                                                        }
                                                        return perceptor;
                                                    }
                                                );
                                                setPersonaPerceptors(updatedPerceptors);
                                            }}
                                        />
                                    </div>
                                </Box>
                                {/* Full Email Content switch*/}
                                {item.listener !== twilioListener && (
                                    <Box className="">
                                        <div className="flex items-center justify-between">
                                            <p className="">Full Email Content</p>
                                            <Switch
                                                // id="collab-alerts"
                                                isChecked={item.fetch_complete_email_content}
                                                disabled={item.campaign ? true : false}
                                                onChange={() => {
                                                    const newFullEmailValue =
                                                        item.fetch_complete_email_content
                                                            ? false
                                                            : true;

                                                    const updatedPerceptors = personaPerceptors.map(
                                                        (perceptor, perceptorIndex) => {
                                                            if (index === perceptorIndex) {
                                                                return {
                                                                    ...perceptor,
                                                                    fetch_complete_email_content:
                                                                        newFullEmailValue
                                                                };
                                                            }
                                                            return perceptor;
                                                        }
                                                    );
                                                    setPersonaPerceptors(updatedPerceptors);
                                                }}
                                            />
                                        </div>
                                    </Box>
                                )}
                            </div>
                        </div>
                    </div>

                    {item.listener === mailgunListener && (
                        <div className="grid grid-cols-1 pt-4">
                            <div className="grid grid-cols-2 gap-6 mb-4 px-4">
                                <div className="">
                                    <p className="mb-1">Filter</p>
                                    <Select
                                        options={filtersOptions}
                                        value={
                                            filtersOptions?.find(
                                                (option) => option.value === item.filter
                                            ) || "Choose filter"
                                        }
                                        onChange={(selectedOption) => {
                                            const updatedPerceptors = personaPerceptors.map(
                                                (perceptor, perceptorIndex) => {
                                                    if (index === perceptorIndex) {
                                                        return {
                                                            ...perceptor,
                                                            filter:
                                                                typeof selectedOption === "string"
                                                                    ? selectedOption
                                                                    : selectedOption?.value
                                                        };
                                                    }
                                                    return perceptor;
                                                }
                                            );
                                            setPersonaPerceptors(updatedPerceptors);
                                        }}
                                        // styles={selectStyles}
                                        placeholder="Choose filter"
                                        className="arin-react-select-container "
                                        classNamePrefix="arin-react-select"
                                    />
                                </div>

                                <div className="">
                                    <p className="mb-1">Actions</p>
                                    <Select
                                        options={actionOptions}
                                        value={
                                            actionOptions?.find(
                                                (option) => option.value === item.action
                                            ) || "Choose action"
                                        }
                                        onChange={(selectedOption) => {
                                            const updatedPerceptors = personaPerceptors.map(
                                                (perceptor, perceptorIndex) => {
                                                    if (index === perceptorIndex) {
                                                        return {
                                                            ...perceptor,
                                                            action:
                                                                typeof selectedOption === "string"
                                                                    ? selectedOption
                                                                    : selectedOption?.value
                                                        };
                                                    }
                                                    return perceptor;
                                                }
                                            );
                                            setPersonaPerceptors(updatedPerceptors);
                                        }}
                                        // styles={selectStyles}
                                        placeholder="Choose action"
                                        className="arin-react-select-container "
                                        classNamePrefix="arin-react-select"
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-6 mb-4 px-4">
                                <div className="">
                                    <p className="mb-1">Filter Values</p>
                                    <Box
                                        border="1px solid #CBD5E0"
                                        borderRadius="md"
                                        p="2"
                                        display="flex"
                                        flexWrap="wrap"
                                        alignItems="flex-start"
                                        minH="35px"
                                        maxH="200px"
                                        overflowY="auto"
                                    >
                                        {item.filter_values?.map((record, itemIndex) => (
                                            <Tag
                                                size="sm"
                                                key={itemIndex}
                                                borderRadius="5px"
                                                fontSize="85%"
                                                m="0.5"
                                                variant="solid"
                                                colorScheme="brand"
                                            >
                                                <TagLabel>{record}</TagLabel>
                                                <TagCloseButton
                                                    disabled={item.campaign ? true : false}
                                                    onClick={() =>
                                                        editFilterValue(index, itemIndex)
                                                    }
                                                />
                                            </Tag>
                                        ))}
                                        <textarea
                                            ref={textareaRef}
                                            rows={1}
                                            disabled={item.campaign ? true : false}
                                            value={filterTextareaValues[index] || ""}
                                            onChange={(e) => handleFilterInputUpdate(e, index)}
                                            onKeyDown={(e) => handleEditFilterKeyDown(e, index)}
                                            placeholder={
                                                item.filter_values?.length === 0
                                                    ? "Filter values, if any"
                                                    : ""
                                            }
                                            style={{
                                                flexGrow: 1,
                                                border: "none",
                                                outline: "none",
                                                resize: "none",
                                                overflow: "hidden",
                                                background: "transparent",
                                                padding: "0",
                                                margin: "0 1px"
                                            }}
                                        />
                                    </Box>
                                </div>
                                {item.action !== "webhook" && (
                                    <div className="">
                                        <p className="mb-1">Action Values</p>
                                        <Box
                                            border="1px solid #CBD5E0"
                                            borderRadius="md"
                                            p="2"
                                            display="flex"
                                            flexWrap="wrap"
                                            alignItems="flex-start"
                                            minH="35px"
                                            maxH="200px"
                                            overflowY="auto"
                                        >
                                            {item.action_values?.map((record, itemIndex) => (
                                                <Tag
                                                    size="sm"
                                                    key={itemIndex}
                                                    borderRadius="5px"
                                                    fontSize="85%"
                                                    m="0.5"
                                                    variant="solid"
                                                    colorScheme="brand"
                                                >
                                                    <TagLabel>{record}</TagLabel>
                                                    <TagCloseButton
                                                        disabled={item.campaign ? true : false}
                                                        onClick={() =>
                                                            editActionValue(index, itemIndex)
                                                        }
                                                    />
                                                </Tag>
                                            ))}
                                            <textarea
                                                ref={textareaRef}
                                                rows={1}
                                                disabled={item.campaign ? true : false}
                                                value={actionTextareaValues[index] || ""}
                                                onChange={(e) => handleActionInputUpdate(e, index)}
                                                onKeyDown={(e) => handleEditActionKeyDown(e, index)}
                                                placeholder={
                                                    item.action_values?.length === 0
                                                        ? "Action values, if any"
                                                        : ""
                                                }
                                                style={{
                                                    flexGrow: 1,
                                                    border: "none",
                                                    outline: "none",
                                                    resize: "none",
                                                    overflow: "hidden",
                                                    background: "transparent",
                                                    padding: "0",
                                                    margin: "0 1px"
                                                }}
                                            />
                                        </Box>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end mt-3 gap-2 px-4">
                        <Button
                            variant="solid"
                            fontSize="14px"
                            colorScheme="brand"
                            size="sm"
                            isLoading={isLoadingList[index]}
                            isDisabled={isDeleteLoadingList[index] || item.campaign ? true : false}
                            onClick={(e) => savePerceptor(index, item.index)}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outline"
                            fontSize="14px"
                            colorScheme="red"
                            size="sm"
                            isDisabled={isLoadingList[index] || item.campaign ? true : false}
                            isLoading={isDeleteLoadingList[index]}
                            onClick={() => deletePerceptor(index, item.id, item.index)}
                        >
                            Delete
                        </Button>
                    </div>
                </Box>
            ))}

            {personaPerceptors.length < 1 && (
                <div className="h-[160px] flex justify-center items-center border rounded-lg bg-neutral-50">
                    <p className="">
                        No perceptors to show. Click on the "Add Perceptor" button to show
                        perceptors
                    </p>
                </div>
            )}

            {/* Modal */}
            <Modal
                isOpen={perceptorFormDisc.isOpen}
                size={"3xl"}
                onClose={perceptorFormDisc.onClose}
                closeOnOverlayClick={false}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <ModalHeader fontWeight={"400"}>Add Perceptor</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <div className="form-body px-2">
                                        <div className="grid grid-cols-2 gap-6 mb-2">
                                            <div className="">
                                                <p className="mb-1">Listener</p>
                                                <Select
                                                    options={listenerOptions}
                                                    value={
                                                        listenerOptions?.find(
                                                            (option) => option.value === listener
                                                        ) || "Choose perceptor"
                                                    }
                                                    onChange={(selectedOption) => {
                                                        if (selectedOption) {
                                                            if (
                                                                typeof selectedOption === "string"
                                                            ) {
                                                                setListener(selectedOption);
                                                            } else {
                                                                setListener(selectedOption.value);
                                                            }
                                                        } else {
                                                            setListener("");
                                                        }
                                                    }}
                                                    // styles={selectStyles}
                                                    placeholder="Choose perceptor"
                                                    className="arin-react-select-container "
                                                    classNamePrefix="arin-react-select"
                                                />
                                            </div>
                                            {listener !== twilioListener &&
                                                listener !== mailgunListener && (
                                                    <div className="">
                                                        <Box
                                                            className="flex flex-col"
                                                            style={{ flexBasis: "18%" }}
                                                        >
                                                            <p className="mb-1">Restrictions</p>
                                                            <Box className="flex flex-col">
                                                                <Box
                                                                    border="1px solid #CBD5E0"
                                                                    borderRadius="md"
                                                                    p="2"
                                                                    display="flex"
                                                                    flexWrap="wrap"
                                                                    alignItems="flex-start"
                                                                    minH="33px"
                                                                    maxH="200px"
                                                                    overflowY="auto"
                                                                >
                                                                    {emails.map((email, index) => (
                                                                        <Tag
                                                                            size="sm"
                                                                            key={index}
                                                                            borderRadius="5px"
                                                                            m="0.5"
                                                                            fontSize="85%"
                                                                            variant="solid"
                                                                            colorScheme="brand"
                                                                        >
                                                                            <TagLabel>
                                                                                {email}
                                                                            </TagLabel>
                                                                            <TagCloseButton
                                                                                onClick={() =>
                                                                                    removeEmail(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Tag>
                                                                    ))}
                                                                    <textarea
                                                                        ref={textareaRef}
                                                                        rows={1}
                                                                        value={inputValue}
                                                                        onChange={handleInputChange}
                                                                        onKeyDown={handleKeyDown}
                                                                        onBlur={() =>
                                                                            handleEmailInput(
                                                                                inputValue.trim()
                                                                            )
                                                                        }
                                                                        placeholder={
                                                                            emails.length === 0
                                                                                ? "Restrictions, if any"
                                                                                : ""
                                                                        }
                                                                        style={{
                                                                            flexGrow: 1,
                                                                            border: "none",
                                                                            outline: "none",
                                                                            resize: "none",
                                                                            overflow: "hidden",
                                                                            background:
                                                                                "transparent",
                                                                            padding: "0",
                                                                            margin: "0 1px",
                                                                            minHeight: "21px"
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                )}

                                            {listener === mailgunListener && (
                                                <div className="">
                                                    <p className="mb-1">Domain Email</p>
                                                    <Select
                                                        options={workEmailOptions}
                                                        value={
                                                            workEmailOptions?.find(
                                                                (option) =>
                                                                    option.value ===
                                                                    workEmailFilters
                                                            ) || "Choose persona's work email"
                                                        }
                                                        onChange={(selectedOption) => {
                                                            if (selectedOption) {
                                                                if (
                                                                    typeof selectedOption ===
                                                                    "string"
                                                                ) {
                                                                    setWorkEmailFilters(
                                                                        selectedOption
                                                                    );
                                                                } else {
                                                                    setWorkEmailFilters(
                                                                        selectedOption.value
                                                                    );
                                                                }
                                                            } else {
                                                                setWorkEmailFilters("");
                                                            }
                                                        }}
                                                        // styles={selectStyles}
                                                        placeholder="Choose persona's work email"
                                                        className="arin-react-select-container "
                                                        classNamePrefix="arin-react-select"
                                                    />
                                                </div>
                                            )}

                                            {listener === twilioListener && (
                                                <div className="">
                                                    <Box
                                                        className="flex flex-col"
                                                        style={{ flexBasis: "18%" }}
                                                    >
                                                        <p className="mb-1">
                                                            Phone Number Whitelist
                                                        </p>
                                                        <Box className="flex flex-col">
                                                            <Box
                                                                border="1px solid #CBD5E0"
                                                                borderRadius="md"
                                                                p="2"
                                                                display="flex"
                                                                flexWrap="wrap"
                                                                alignItems="flex-start"
                                                                minH="33px"
                                                                maxH="200px"
                                                                overflowY="auto"
                                                            >
                                                                {phoneNumbers.map(
                                                                    (phoneNumber, index) => (
                                                                        <Tag
                                                                            size="sm"
                                                                            key={index}
                                                                            borderRadius="5px"
                                                                            m="0.5"
                                                                            fontSize="85%"
                                                                            variant="solid"
                                                                            colorScheme="brand"
                                                                        >
                                                                            <TagLabel>
                                                                                {phoneNumber}
                                                                            </TagLabel>
                                                                            <TagCloseButton
                                                                                onClick={() =>
                                                                                    removePhoneNumber(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Tag>
                                                                    )
                                                                )}
                                                                <textarea
                                                                    ref={phoneNumberTextareaRef}
                                                                    rows={1}
                                                                    value={phoneNumberInputValue}
                                                                    onChange={
                                                                        handlePhoneNumberInputChange
                                                                    }
                                                                    onKeyDown={
                                                                        handlePhoneNumberKeyDown
                                                                    }
                                                                    onBlur={() =>
                                                                        handlePhoneNumberInput(
                                                                            phoneNumberInputValue.trim()
                                                                        )
                                                                    }
                                                                    placeholder={
                                                                        phoneNumbers.length === 0
                                                                            ? "Whitelist phone number"
                                                                            : ""
                                                                    }
                                                                    style={{
                                                                        flexGrow: 1,
                                                                        border: "none",
                                                                        outline: "none",
                                                                        resize: "none",
                                                                        overflow: "hidden",
                                                                        background: "transparent",
                                                                        padding: "0",
                                                                        margin: "0 1px",
                                                                        minHeight: "21px"
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            )}
                                        </div>

                                        <div className="mb-8">
                                            <div className="py-3 mt-2 mb-4">
                                                <Box
                                                    className="flex flex-col"
                                                    style={{ flexBasis: "32%" }}
                                                >
                                                    <p className="mb-1">
                                                        Skill to engage following perception
                                                    </p>
                                                    <Select
                                                        isMulti
                                                        isClearable={false}
                                                        name="perceptor_skills"
                                                        options={skillsOptions}
                                                        value={
                                                            skills.length > 0
                                                                ? skillsOptions?.find(
                                                                      (option) =>
                                                                          option.value === skills
                                                                  )
                                                                : "placeholder"
                                                        }
                                                        // value={skillsOptions?.find(option => option.value === skills)}
                                                        onChange={(selectedOptionList) => {
                                                            if (selectedOptionList) {
                                                                setSkills(
                                                                    selectedOptionList.map(
                                                                        (item) => item
                                                                    )
                                                                );
                                                            } else {
                                                                setSkills([]);
                                                            }
                                                        }}
                                                        placeholder="Add skill to engage when action is detected"
                                                        className="arin-react-select-container "
                                                        classNamePrefix="arin-react-select"
                                                    />
                                                </Box>
                                            </div>

                                            <div className="">
                                                <div className="flex justify-between items-center mb-4">
                                                    <div className="">
                                                        <label className="">Force Collab</label>
                                                        <p className="text-sm text-neutral-400">
                                                            Force the persona to execute the skill
                                                            directly.
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center justify-center">
                                                        <Switch
                                                            // id="collab-alerts"
                                                            isChecked={enableCollab === "yes"}
                                                            onChange={forceCollab}
                                                        />
                                                    </div>
                                                </div>
                                                {listener !== twilioListener && (
                                                    <div className="flex justify-between items-center">
                                                        <div className="">
                                                            <label className="">
                                                                Read Full Email
                                                            </label>
                                                            <p className="text-sm text-neutral-400">
                                                                Let the persona to read the full
                                                                contents of emails.
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center justify-center">
                                                            <Switch
                                                                // id="collab-alerts"
                                                                isChecked={isFullEmailContent}
                                                                onChange={toggleFullEmailContent}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {listener !== twilioListener &&
                                            listener !== mailgunListener && (
                                                <Accordion
                                                    allowToggle
                                                    border="1px solid #f2f2f2"
                                                    borderTop="1px solid #f2f2f2"
                                                    borderBottom="1px solid #f2f2f2"
                                                    borderRadius={"8px"}
                                                >
                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton>
                                                                <Box
                                                                    as="span"
                                                                    flex="1"
                                                                    textAlign="left"
                                                                >
                                                                    Advanced
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={1}>
                                                            <div className="grid grid-cols-2 gap-6">
                                                                <div className="py-3">
                                                                    <Box
                                                                        className="flex flex-col"
                                                                        style={{ flexBasis: "18%" }}
                                                                    >
                                                                        <p className="mb-1">
                                                                            Receiver Email Whitelist
                                                                        </p>
                                                                        <Box className="flex flex-col">
                                                                            <Box
                                                                                border="1px solid #CBD5E0"
                                                                                borderRadius="md"
                                                                                p="2"
                                                                                display="flex"
                                                                                flexWrap="wrap"
                                                                                alignItems="flex-start"
                                                                                minH="33px"
                                                                                maxH="200px"
                                                                                overflowY="auto"
                                                                            >
                                                                                {receiverEmails.map(
                                                                                    (
                                                                                        email,
                                                                                        index
                                                                                    ) => (
                                                                                        <Tag
                                                                                            size="sm"
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            borderRadius="5px"
                                                                                            m="0.5"
                                                                                            fontSize="85%"
                                                                                            variant="solid"
                                                                                            colorScheme="brand"
                                                                                        >
                                                                                            <TagLabel>
                                                                                                {
                                                                                                    email
                                                                                                }
                                                                                            </TagLabel>
                                                                                            <TagCloseButton
                                                                                                onClick={() =>
                                                                                                    removeReceiverEmail(
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </Tag>
                                                                                    )
                                                                                )}
                                                                                <textarea
                                                                                    ref={
                                                                                        textareaRef
                                                                                    }
                                                                                    rows={1}
                                                                                    value={
                                                                                        receiverInputValue
                                                                                    }
                                                                                    onChange={
                                                                                        handleReceiverInputChange
                                                                                    }
                                                                                    onKeyDown={
                                                                                        handleReceiverKeyDown
                                                                                    }
                                                                                    onBlur={() =>
                                                                                        handleReceiverEmailInput(
                                                                                            receiverInputValue.trim()
                                                                                        )
                                                                                    }
                                                                                    placeholder={
                                                                                        receiverEmails.length ===
                                                                                        0
                                                                                            ? "Whitelist receiver email"
                                                                                            : ""
                                                                                    }
                                                                                    style={{
                                                                                        flexGrow: 1,
                                                                                        border: "none",
                                                                                        outline:
                                                                                            "none",
                                                                                        resize: "none",
                                                                                        overflow:
                                                                                            "hidden",
                                                                                        background:
                                                                                            "transparent",
                                                                                        padding:
                                                                                            "0",
                                                                                        margin: "0 1px",
                                                                                        minHeight:
                                                                                            "21px"
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            )}

                                        {listener === mailgunListener && (
                                            <Accordion
                                                allowToggle
                                                border="1px solid #f2f2f2"
                                                borderTop="1px solid #f2f2f2"
                                                borderBottom="1px solid #f2f2f2"
                                                borderRadius={"8px"}
                                            >
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box
                                                                as="span"
                                                                flex="1"
                                                                textAlign="left"
                                                            >
                                                                Advanced
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel
                                                        pb={1}
                                                        style={{ overflowY: "auto" }}
                                                    >
                                                        <>
                                                            <div className="grid grid-cols-2 gap-6 mb-4">
                                                                <div className="">
                                                                    <p className="mb-1">Filter</p>
                                                                    <Select
                                                                        options={filtersOptions}
                                                                        value={
                                                                            filtersOptions?.find(
                                                                                (option) =>
                                                                                    option.value ===
                                                                                    filters
                                                                            ) || "Choose filter"
                                                                        }
                                                                        onChange={(
                                                                            selectedOption
                                                                        ) => {
                                                                            if (selectedOption) {
                                                                                if (
                                                                                    typeof selectedOption ===
                                                                                    "string"
                                                                                ) {
                                                                                    setFilters(
                                                                                        selectedOption
                                                                                    );
                                                                                } else {
                                                                                    setFilters(
                                                                                        selectedOption.value
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                setFilters("");
                                                                            }
                                                                        }}
                                                                        // styles={selectStyles}
                                                                        placeholder="Choose filter"
                                                                        className="arin-react-select-container "
                                                                        classNamePrefix="arin-react-select"
                                                                    />
                                                                </div>

                                                                <div className="">
                                                                    <p className="mb-1">Actions</p>
                                                                    <Select
                                                                        options={actionOptions}
                                                                        value={
                                                                            actionOptions?.find(
                                                                                (option) =>
                                                                                    option.value ===
                                                                                    actions
                                                                            ) ||
                                                                            actionOptions?.find(
                                                                                (option) =>
                                                                                    option.value ===
                                                                                    "webhook"
                                                                            ) ||
                                                                            "Choose action"
                                                                        }
                                                                        onChange={(
                                                                            selectedOption
                                                                        ) => {
                                                                            if (selectedOption) {
                                                                                if (
                                                                                    typeof selectedOption ===
                                                                                    "string"
                                                                                ) {
                                                                                    setActions(
                                                                                        selectedOption
                                                                                    );
                                                                                } else {
                                                                                    setActions(
                                                                                        selectedOption.value
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                setActions("");
                                                                            }
                                                                        }}
                                                                        // styles={selectStyles}
                                                                        placeholder="Choose action"
                                                                        className="arin-react-select-container "
                                                                        classNamePrefix="arin-react-select"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="grid grid-cols-2 gap-6 mb-4">
                                                                {filters !== "" && (
                                                                    <div className="py-3">
                                                                        <Box
                                                                            className="flex flex-col"
                                                                            style={{
                                                                                flexBasis: "18%"
                                                                            }}
                                                                        >
                                                                            <p className="mb-1">
                                                                                Filter Values
                                                                            </p>
                                                                            <Box className="flex flex-col">
                                                                                <Box
                                                                                    border="1px solid #CBD5E0"
                                                                                    borderRadius="md"
                                                                                    p="2"
                                                                                    display="flex"
                                                                                    flexWrap="wrap"
                                                                                    alignItems="flex-start"
                                                                                    minH="33px"
                                                                                    maxH="200px"
                                                                                    overflowY="auto"
                                                                                >
                                                                                    {filterValues.map(
                                                                                        (
                                                                                            value,
                                                                                            index
                                                                                        ) => (
                                                                                            <Tag
                                                                                                size="sm"
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                borderRadius="5px"
                                                                                                m="0.5"
                                                                                                fontSize="85%"
                                                                                                variant="solid"
                                                                                                colorScheme="brand"
                                                                                            >
                                                                                                <TagLabel>
                                                                                                    {
                                                                                                        value
                                                                                                    }
                                                                                                </TagLabel>
                                                                                                <TagCloseButton
                                                                                                    onClick={() =>
                                                                                                        removeFilterValue(
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Tag>
                                                                                        )
                                                                                    )}
                                                                                    <textarea
                                                                                        ref={
                                                                                            textareaRef
                                                                                        }
                                                                                        rows={1}
                                                                                        value={
                                                                                            filterInputValue
                                                                                        }
                                                                                        onChange={
                                                                                            handleFilterInputChange
                                                                                        }
                                                                                        onKeyDown={
                                                                                            handleFilterKeyDown
                                                                                        }
                                                                                        onBlur={() =>
                                                                                            handleFilterInput(
                                                                                                filterInputValue.trim()
                                                                                            )
                                                                                        }
                                                                                        placeholder={
                                                                                            filterValues.length ===
                                                                                            0
                                                                                                ? "Enter values for your filter"
                                                                                                : ""
                                                                                        }
                                                                                        style={{
                                                                                            flexGrow: 1,
                                                                                            border: "none",
                                                                                            outline:
                                                                                                "none",
                                                                                            resize: "none",
                                                                                            overflow:
                                                                                                "hidden",
                                                                                            background:
                                                                                                "transparent",
                                                                                            padding:
                                                                                                "0",
                                                                                            margin: "0 1px",
                                                                                            minHeight:
                                                                                                "21px"
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </div>
                                                                )}

                                                                {actions !== "webhook" && (
                                                                    <div className="py-3">
                                                                        <Box
                                                                            className="flex flex-col"
                                                                            style={{
                                                                                flexBasis: "18%"
                                                                            }}
                                                                        >
                                                                            <p className="mb-1">
                                                                                Action Emails
                                                                            </p>
                                                                            <Box className="flex flex-col">
                                                                                <Box
                                                                                    border="1px solid #CBD5E0"
                                                                                    borderRadius="md"
                                                                                    p="2"
                                                                                    display="flex"
                                                                                    flexWrap="wrap"
                                                                                    alignItems="flex-start"
                                                                                    minH="33px"
                                                                                    maxH="200px"
                                                                                    overflowY="auto"
                                                                                >
                                                                                    {actionValues.map(
                                                                                        (
                                                                                            email,
                                                                                            index
                                                                                        ) => (
                                                                                            <Tag
                                                                                                size="sm"
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                                borderRadius="5px"
                                                                                                m="0.5"
                                                                                                fontSize="85%"
                                                                                                variant="solid"
                                                                                                colorScheme="brand"
                                                                                            >
                                                                                                <TagLabel>
                                                                                                    {
                                                                                                        email
                                                                                                    }
                                                                                                </TagLabel>
                                                                                                <TagCloseButton
                                                                                                    onClick={() =>
                                                                                                        removeActionValue(
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </Tag>
                                                                                        )
                                                                                    )}
                                                                                    <textarea
                                                                                        ref={
                                                                                            textareaRef
                                                                                        }
                                                                                        rows={1}
                                                                                        value={
                                                                                            actionInputValue
                                                                                        }
                                                                                        onChange={
                                                                                            handleActionInputChange
                                                                                        }
                                                                                        onKeyDown={
                                                                                            handleActionKeyDown
                                                                                        }
                                                                                        onBlur={() =>
                                                                                            handleActionInput(
                                                                                                actionInputValue.trim()
                                                                                            )
                                                                                        }
                                                                                        placeholder={
                                                                                            actionValues.length ===
                                                                                            0
                                                                                                ? "Action emails"
                                                                                                : ""
                                                                                        }
                                                                                        style={{
                                                                                            flexGrow: 1,
                                                                                            border: "none",
                                                                                            outline:
                                                                                                "none",
                                                                                            resize: "none",
                                                                                            overflow:
                                                                                                "hidden",
                                                                                            background:
                                                                                                "transparent",
                                                                                            padding:
                                                                                                "0",
                                                                                            margin: "0 1px",
                                                                                            minHeight:
                                                                                                "21px"
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        )}
                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <Button
                                        type="submit"
                                        isLoading={isSubmitting}
                                        colorScheme="brand"
                                        mr={3}
                                    >
                                        Save
                                    </Button>
                                    <Button onClick={perceptorFormDisc.onClose} variant="outline">
                                        Close
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default PerceptorList;
