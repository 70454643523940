import { Button, ButtonGroup } from "@chakra-ui/react";
import { BsUiChecks } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function SubscriptionCancelledView() {
    const navigate = useNavigate();
    const backToApp = () => {
        navigate("/");
    };

    return (
        <div className="h-screen w-screen relative bg-gray-50 flex flex-col justify-center items-center">
            <img
                src="/neo_ai_logo_black.png"
                alt=""
                className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]"
            />
            <BsUiChecks className="text-red-600" size={"55px"} />
            <h1 className="text-red-900 text-[22px] mt-[36px] mb-[8px] font-semibold">
                Your payment was cancelled
            </h1>
            <p className="mb-[40px] text-slate-500 max-w-[450px] text-center text-[15px]">
                Your payment was not completed. You can still subscribe by clicking the subscribe
                button on your account page
            </p>
            <ButtonGroup spacing={4}>
                <Button
                    onClick={() => navigate("/account/profile")}
                    variant="solid"
                    colorScheme="brand"
                    size="sm"
                >
                    Go to Account page
                </Button>
                <Button onClick={() => navigate("/")} variant="outline" size="sm">
                    Go to Home page
                </Button>
            </ButtonGroup>
        </div>
    );
}
