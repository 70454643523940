import "../styles/NotFound.component.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setMetaTags } from "../utils/seo.utils";
import { ButtonGroup, Button } from "@chakra-ui/react";
import { TbError404 } from "react-icons/tb";
import { ROUTES } from "../routes";

export default function NotFoundView() {
    const navigate = useNavigate();

    useEffect(() => {
        setMetaTags({ title: "Page Not found" });
    }, []);

    return (
        <div className="h-screen w-screen relative bg-gray-50 flex flex-col justify-center items-center">
            <img
                src="https://assets.neoworlder.com/img/v1/lifelab_logo_black.png"
                alt=""
                className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]"
            />
            <TbError404 className="text-red-600" size={"55px"} />
            <h1 className="text-red-900 text-[22px] mt-[36px] mb-[8px] font-semibold">
                404 - Lost in Space
            </h1>
            <p className="mb-[10px] text-slate-500 max-w-[450px] text-center text-[15px]">
                We can't seem to find the page you're looking for. It might have been moved,
                renamed, or maybe it never existed!
            </p>
            <ul className="mb-[40px] text-center text-slate-500 max-w-[450px] text-[15px]">
                <li className="text-slate-500 mb-1 max-w-[450px] text-[15px]">
                    <strong>Don't Panic:</strong> It's just a wrong turn in the vast internet
                    universe.
                </li>
                <li className="text-slate-500 mb-1 max-w-[450px] text-[15px]">
                    <strong>Navigation Tips:</strong> Check the URL for typos.
                </li>
                <ul className="pl-4 text-center">
                    <li className="text-slate-500 max-w-[450px] text-[15px]">
                        Use the navigation menu to find your way.
                    </li>
                    <li className="text-slate-500 max-w-[450px] text-[15px]">
                        Return to our Home Page.
                    </li>
                    <li className="text-slate-500 max-w-[450px] text-[15px]">
                        Need Assistance? If you're sure this page should exist, contact our Support
                        Team for help.
                    </li>
                </ul>
            </ul>

            <ButtonGroup spacing={4}>
                <Button onClick={() => navigate(ROUTES.PERSONAS)} variant="outline" size="sm">
                    Go to Home page
                </Button>
            </ButtonGroup>
        </div>
    );
}
