import { UserProfile } from "../../models/user.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
    isLoggedIn: boolean;
    user: UserProfile | any;
}

const initialState: AuthState = {
    isLoggedIn: false,
    user: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthState(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },
        setAuthUser(state, action: PayloadAction<UserProfile>) {
            state.user = action.payload;
        },
        updateUserProfile(state, action: PayloadAction<any>) {
            console.log(action.payload);
            const payloadData = {
                ...state.user,
                ...action.payload,
                ...(action.payload.name && { fullname: action.payload.name })
            };
            delete payloadData.name;
            state.user = payloadData;
            // remove unnecessary fields
            // update cookies

            // updateUserSession(payloadData);
        }
    }
});

export const { setAuthState, updateUserProfile, setAuthUser } = authSlice.actions;

export const selectAuthState = (state: { auth: AuthState }) => state.auth.isLoggedIn;

export default authSlice.reducer;
