// EmailList.tsx
import React, { useEffect, useState } from "react";
import { Input, Button, Select, Box, useToast } from "@chakra-ui/react";
import { useHttpClient } from "../utils/http.utils";
import { useSelector } from "react-redux";
import { PersonaState } from "../store/slices/persona.slice";
import hotToast from "react-hot-toast";

type EmailItem = {
    email: string;
    role: "admin" | "regular";
};

interface EmailListProps {
    organizationId?: string;
}

const EmailList: React.FC<EmailListProps> = (props: EmailListProps) => {
    const [email, setEmail] = useState("");
    const [role, setRole] = useState<"admin" | "regular">("regular");
    const [emails, setEmails] = useState<EmailItem[]>([]);
    const { createPersonaInvitationApi, listPersonaInvitationApi, deletePersonaInvitationApi } =
        useHttpClient();
    const toast = useToast();
    const personaState: PersonaState = useSelector((state: any) => state.persona);
    const { id: personaId } = personaState.personaAttributesForm;

    useEffect(() => {
        listPersonaInvitationApi(personaId).then((data) => {
            setEmails(
                data.map((personaInvite) => ({
                    email: personaInvite.email,
                    role: personaInvite.role
                }))
            );
        });
    }, [personaId]);

    const addEmail = () => {
        if (email) {
            // Setup organization before adding invites
            if (!props.organizationId) {
                hotToast.error("Please set up your organization before you add a member");
                return;
            }

            // check if the email already exist in state
            const emailExist = emails.find((record) => record.email === email);
            // if exist, show warning that the user has aleady been added
            if (emailExist) {
                toast({
                    title: "This user has already been added",
                    status: "warning"
                });
                return;
            }

            setEmail(""); // Clear the input
            // send invitation api
            createPersonaInvitationApi(personaId as string, email, role, props.organizationId)
                .then(() => {
                    setEmails([{ email, role }, ...emails]);
                })
                .catch(() => {
                    toast({
                        title: "Cannot invite this user at this time, Please try again alter",
                        status: "error"
                    });
                });
        } else {
            toast({
                title: "Please add an email",
                status: "warning"
            });
            return;
        }
    };

    const removeEmail = (index: number) => {
        if (personaId && emails[index]) {
            deletePersonaInvitationApi(personaId, emails[index].email)
                .then(() => {
                    setEmails(emails.filter((_, i) => i !== index));
                })
                .catch(console.error);
        }
    };

    return (
        <Box className="space-y-4">
            {emails.map((item, index) => (
                <Box
                    key={index}
                    className="grid grid-cols-[auto_150px_150px] py-3 items-center border-b space-x-4"
                >
                    <p className="text-[15px]">{item.email}</p>
                    <div className="px-4">
                        <Select
                            value={item.role}
                            className="w-full"
                            onChange={(e) => {
                                const newEmails = [...emails];
                                newEmails[index].role = e.target.value as "admin" | "regular";
                                setEmails(newEmails);
                            }}
                        >
                            <option value="admin">Admin</option>
                            <option value="regular">Regular</option>
                        </Select>
                    </div>
                    <Button
                        colorScheme="red"
                        variant={"link"}
                        size={"sm"}
                        onClick={() => removeEmail(index)}
                    >
                        Remove
                    </Button>
                </Box>
            ))}

            <Box className="flex items-center space-x-4 w-full">
                <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className=""
                    placeholder="Enter email"
                    width={"400px"}
                />
                <Select
                    value={role}
                    onChange={(e) => setRole(e.target.value as "admin" | "regular")}
                    className=""
                    width={"180px"}
                >
                    <option value="admin">Admin</option>
                    <option value="regular">Regular</option>
                </Select>
                <Button
                    variant="outline"
                    fontSize={"14px"}
                    colorScheme="brand"
                    width={""}
                    onClick={addEmail}
                    className=""
                >
                    Add
                </Button>
            </Box>
        </Box>
    );
};

export default EmailList;
