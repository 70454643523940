interface ProfileAvatarProps {
    src?: string;
    type?: "user" | "persona";
    size?: string;
}

export function ProfileAvatar({ src, type = "user", size = "35px" }: ProfileAvatarProps) {
    const onImageLoadError = (e) => {
        e.target.src = type === "persona" ? "/img/persona_avatar.jpg" : "/user_avatar.png";
    };
    return (
        <img
            src={src || (type === "persona" ? "/img/persona_avatar.jpg" : "/user_avatar.png")}
            alt="avatar"
            style={{ height: size, width: size }}
            className="rounded-full object-cover"
            onError={onImageLoadError}
        />
    );
}
